export default function ListHeader({
	name,
	isSortable,
	onSortClick,
	onOpenClose,
	isOpen,
	error,
}) {
	return (
		<div
			className={`flex bg-white p-[8px] justify-between items-center border-[1px] border-solid ${
				error ? "border-red-500" : "border-lightGrey"
			}`}
		>
			<div className="flex justify-center items-center">
				{/* <div className="w-[32px] h-[32px] flex justify-center items-center bg-lightBrown mr-[8px]">
          <img className="content-menuIcon" alt="" />
        </div> */}

				<span className="text-standard text-primaryBlue font-latoBold">
					{name}
				</span>
			</div>

			<div className="flex">
				{isSortable && (
					<div
						className="w-[32px] h-[32px] flex justify-center items-center bg-lightBrown mr-3"
						onClick={() => onSortClick(name)}
						title="sortere i faldende rækkefølge"
					>
						<img
							className=" content-sortIcon"
							alt=""
						/>
					</div>
				)}
				<div
					className="w-[32px] h-[32px] flex justify-center items-center bg-lightBrown"
					onClick={onOpenClose}
				>
					{isOpen ? (
						<img
							className=" content-arrowUpIcon"
							alt=""
						/>
					) : (
						<img
							className=" content-arrowDownIcon"
							alt=""
						/>
					)}
				</div>
			</div>
		</div>
	);
}
