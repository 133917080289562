import LoadingSpinner from "__utilities/components/LoadingSpinner/LoadingSpinner";
import Popup from "__utilities/components/Popup";
import React from "react";

export default function LoadingPopup({ percentage, processing }) {
	return (
		<Popup>
			<LoadingSpinner />
			{percentage && !processing && (
				<>
					<div className="text-primaryBlue font-latoBold flex flex-col justify-center items-center text-standard">
						{percentage} %
					</div>
					<div className="w-full bg-gray-200 rounded-full mb-3 h-2.5 dark:bg-gray-700">
						<div
							className="bg-blue-600 h-2.5 rounded-full"
							style={{ width: `${percentage}%` }}
						></div>
					</div>
				</>
			)}
			{
				processing && (
				<>
					<div className="text-primaryBlue font-latoBold flex flex-col justify-center items-center text-standard">
						... Behandler uploadet fil ...
					</div>
					<div className="mb-3 h-2.5">
					</div>
				</>
			)}
			<div className="text-primaryBlue font-latoBold flex flex-col justify-center items-center text-standard ">
				Prøv at se om du kan nå at lave en kop kaffe, mens din ekstrakt
				pakkes ud.
			</div>
		</Popup>
	);
}
