import React from "react";

export default function Popup({ styles, children }) {
  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className={
        `flex flex-col justify-center items-center bg-white w-[33%] py-[39px] px-[10px] ${styles}`
      }
    >
      {children}
    </div>
  );
}
