export function addToSession(key, content) {
    let previousContent = sessionStorage.getItem(key);
    if(previousContent === null){
        sessionStorage.setItem(key, JSON.stringify(content));
    } else {
        let array = JSON.parse(previousContent)
        array.map((prev) => [
            ...prev,
            content
        ])
        sessionStorage.setItem(key,array);
    }

}

export function getFromSession(key){ 
    let content = sessionStorage.getItem(key)
    if(content !== null){
        return JSON.parse(content);
    }
}

export function removeFromSession(key) {
    sessionStorage.removeItem(key);
}

export function clearExtractSession(){
    removeFromSession("headers_data");
    removeFromSession("case_info_form");
    removeFromSession("advanced_form");
    removeFromSession("uploaded_files");
    removeFromSession("extract_in_progress");
}