import React from "react";

const UploadFile = React.forwardRef(
  (
    {
      onUploadedFile,
      type,
      multipleSelect = true,
      tooltipContent,
      tooltipId,
      isEnabled = true,
      children,
    },
    ref
  ) => {
    const onClickHandler = (e) => {
      if (isEnabled) {
        e.stopPropagation();
        ref.current.click();
      }
    };

    const onChange = (e) => {
      if (isEnabled) {
        e.preventDefault();
        e.stopPropagation();
        if (e.target.files && e.target.files[0]) {
          onUploadedFile(e.target.files);
        }
        e.target.value = null; // clearing input
      }
    };

    const onDrop = (e) => {
      if (isEnabled) {
        e.preventDefault();
        e.stopPropagation();
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
          onUploadedFile(e.dataTransfer.files);
        }
      }
    };

    const handleDrag = function (e) {
      if (isEnabled) {
        e.preventDefault();
        e.stopPropagation();
      }
    };

    return (
      <div
        onClick={onClickHandler}
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={onDrop}
        data-tooltip-content={tooltipContent}
        data-tooltip-id={tooltipId}
        data-tooltip-delay-show={800}
      >
        <input
          ref={ref}
          type="file"
          multiple={multipleSelect}
          onChange={onChange}
          accept={type}
          className={`hidden`}
        />
        {children}
      </div>
    );
  }
);

export default UploadFile;
