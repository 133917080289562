import { useState } from "react";

export default function useFormValidationBilling(
	companyName,
	companyAddress,
	postnumber,
	city,
	country,
	email,
	cvr,
	isTermsAccepted
) {
	const [errorCompanyName, setErrorCompanyName] = useState("");
	const [errorCompanyAddress, setErrorCompanyAddress] = useState("");
	const [errorPostnumber, setErrorPostnumber] = useState("");
	const [errorCity, setErrorCity] = useState("");
	const [errorCountry, setErrorCountry] = useState("");
	const [errorEmail, setErrorEmail] = useState("");
	const [errorCvr, setErrorCvr] = useState("");
	const [errorIsTermsAccepted, setErrorIsTermsAccepted] = useState("");

	const clearErrorStates = () => {
		setErrorCompanyName("");
		setErrorCompanyAddress("");
		setErrorPostnumber("");
		setErrorCity("");
		setErrorCountry("");
		setErrorEmail("");
		setErrorCvr("");
	};

	const validateForm = (showAcceptingTerms) => {
		let isValid = true;

		clearErrorStates();

		if (companyName === null || companyName === "") {
			isValid = false;
			setErrorCompanyName("Required");
		}

		if (companyAddress === null || companyAddress === "") {
			isValid = false;
			setErrorCompanyAddress("Required");
		}

		if (postnumber === null || postnumber === "") {
			isValid = false;
			setErrorPostnumber("Required");
		}

		if (city === null || city === "") {
			isValid = false;
			setErrorCity("Required");
		}

		if (country === null || country === "") {
			isValid = false;
			setErrorCountry("Required");
		}

		if (
			email === null ||
			!email
				.toLowerCase()
				.match(
					/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
				)
		) {
			isValid = false;
			setErrorEmail("Required");
		}

		if (cvr === null || cvr == "") {
			isValid = false;
			setErrorCvr("Required");
		}

		if (showAcceptingTerms && (isTermsAccepted === null || isTermsAccepted == false)) {
			isValid = false;
			setErrorIsTermsAccepted("Required");
		}

		return isValid;
	};

	return {
		errorCompanyName,
		errorCompanyAddress,
		errorPostnumber,
		errorCity,
		errorCountry,
		errorEmail,
		errorCvr,
		errorIsTermsAccepted,
		validateForm,
	};
}
