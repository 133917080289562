import { AppConfig } from "AppConfig";
import { getRequestHeaders } from "msal/MsalAuthProvider";
import { createLog } from "_data/services/extract/service";

export const getUserInfoRequest = (callback) => {
  fetch(`${AppConfig.SERVICE_BASE_URL}/api/v1/auth/me`, {
    method: "GET",
    headers: getRequestHeaders(),
  })
    .then((response) => response.json())
    .then((response) => {
      callback(true, response);
    })
    .catch((error) => {
      callback(false, null);
      createLog("Error", `Get User Info : ${error}`);
    });
};

export const deleteUserRequest = (callback) => {
  fetch(`${AppConfig.SERVICE_BASE_URL}/api/v1/auth/me`, {
    method: "DELETE",
    headers: getRequestHeaders(),
  })
    .then((response) => {
      response.json();
    })
    .then(() => callback())
    .catch((error) => {
      createLog("Error", `Delete User : ${error}`);
    });
};
