import { getUTCDateTime, getUuid } from "__utilities/functions/public";
import React, { useContext, useState } from "react";
import {
  editExtractedFileRequest,
  extractDataFromPDFRequest,
  getHeadersRequest,
  validatePdfFileRequest,
  getLogoPositionsRequest,
  margeDocumentsRequest,
  mergeProgressRequest,
} from "../../services/extract/service";
import { overlayContext } from "../overlay";
import DownloadPopup from "_features/DownloadPopup";
import LoadingPopup from "_features/LoadingPopup";
import ErrorPopup from "_features/ErrorPopup";
import { useEffect } from "react";
import useFormValidation from "../../hooks/extract/useFormValidation";
import { Outlet, useOutletContext } from "react-router-dom";
import {
  addToSession,
  getFromSession,
  removeFromSession,
} from "__utilities/functions/sessionHandler";

export const extractContext = React.createContext({});

export const ExtractProvider = ({ children }) => {
  const [headers, setHeaders] = useState([]);
  let courtFromSessionStorage = getFromSession("court_type");
  const [selectedCourt, setSelectedCourt] = useState(
    courtFromSessionStorage ? courtFromSessionStorage?.type : null
  );
  const [showCaseInfoForm, setShowCaseInfoForm] = useState(false);
  const [showAdvancedForm, setShowAdvancedForm] = useState(false);

  const {
    userInfo,
    setUserInfo,
    showCourts,
    setShowCourts,
    setIsExtractActive,
  } = useOutletContext();

  const [selectedImage, setSelectedImage] = useState("");
  const [selectedPdf, setSelectedPdf] = useState("");

  const [selectedStartDate, setSelectedStartDate] = useState();

  const [courtNumber, setCourtNumber] = useState("");
  const [nameOfPlaintiff, setNameOfPlaintiff] = useState("");
  const [cvrOfPlaintiff, setCvrOfPlaintiff] = useState("");
  const [addressOfPlaintiff, setAddressOfPlaintiff] = useState("");
  const [lawyerOfPlaintiff, setLawyerOfPlaintiff] = useState("");
  const [nameOfDefendant, setNameOfDefendant] = useState("");
  const [addressOfDefendant, setAddressOfDefendant] = useState("");
  const [lawyerOfDefendant, setLawyerOfDefendant] = useState("");
  const [cvrOfDefendant, setCvrOfDefendant] = useState("");
  const [highCourtText, setHighCourtText] = useState("");
  const [isPlaintiffLawyerAppointed, setIsPlaintiffLawyerAppointed] =
    useState(false);
  const [isDefendantLawyerAppointed, setIsDefendantLawyerAppointed] =
    useState(false);
  const [numberOfJudges, setNumberOfJudges] = useState("");

  const [printBothSide, setPrintBothSide] = useState(false);

  const [addTimeline, setAddTimeline] = useState(false);

  const [selectedLogoPosition, setSelectedLogoPosition] = useState(false);

  const [logoPositions, setLogoPositions] = useState([]);

  const [blockTypeSwitch, setBlockTypeSwitch] = useState(false);

  const { setOverlay, setOverlayLock } = useContext(overlayContext); // { show: true, content: <div></div> }

  const [extractedData, setExtractedData] = useState(null);

  const [extractId, setExtractId] = useState(null);
  const [createExtractId, setCreateExtractId] = useState(null);

  const [editKey, setEditKey] = useState(null);

  const [path, setPath] = useState(null);

  const chunkSize = 10 * 1024 * 1024;
  const [currentChunkIndex, setCurrentChunkIndex] = useState(null);
  const [processing, setProcessing] = useState(null);
  const [uploadFile, setUploadFile] = useState();
  const [uploadSuccess, setUploadSuccess] = useState(null);
  const [uploadKey, setUploadKey] = useState(null);

  const [mergeProgress, setMergeProgress] = useState(null);

  const {
    errorCourtNumber,
    errorNameOfPlaintiff,
    errorAddressOfPlaintiff,
    errorLawyerOfPlaintiff,
    errorCvrOfPlaintiff,
    errorNameOfDefendant,
    errorAddressOfDefendant,
    errorLawyerOfDefendant,
    errorCvrOfDefendant,
    errorHighCourtText,
    dataAndTimeError,
    companyLogoError,
    validateForm,
    clearErrorStates,
  } = useFormValidation(
    selectedCourt,
    courtNumber,
    nameOfPlaintiff,
    addressOfPlaintiff,
    lawyerOfPlaintiff,
    nameOfDefendant,
    addressOfDefendant,
    lawyerOfDefendant,
    selectedStartDate
  );

  useEffect(() => {
    setCreateExtractId(getUuid())
  },[])

  useEffect(() => {
    if (mergeProgress) {
      setOverlay({
        show: true,
        content: (
          <DownloadPopup
            progress={mergeProgress}
            clearSessionStorage={clearSessionStorage}
          />
        ),
      });
    }
  }, [mergeProgress]);

  const hasChanges = () => {
    const headersChanged = headers.some((h) => h.usedFiles.length > 0);

    if (
      courtNumber !== "" ||
      nameOfPlaintiff !== "" ||
      cvrOfPlaintiff !== "" ||
      addressOfPlaintiff !== "" ||
      lawyerOfPlaintiff !== "" ||
      nameOfDefendant !== "" ||
      addressOfDefendant !== "" ||
      lawyerOfDefendant !== "" ||
      cvrOfDefendant !== "" ||
      highCourtText !== "" ||
      selectedImage !== "" ||
      selectedPdf !== "" ||
      addTimeline !== false ||
      headersChanged !== false ||
      selectedStartDate !== undefined
    ) {
      return true;
    } else {
      return false;
    }
  };

  const clearSessionStorage = () => {
    removeFromSession("headers_data");
    removeFromSession("case_info_form");
    removeFromSession("advanced_form");
    removeFromSession("uploaded_files");
    removeFromSession("extract_in_progress");
    clearFields(setShowCaseInfoForm, setShowAdvancedForm);
  };

  const clearFields = (setShowCaseInfoForm, setShowAdvancedForm) => {
    setCourtNumber("");
    setNameOfPlaintiff("");
    setCvrOfPlaintiff("");
    setAddressOfPlaintiff("");
    setLawyerOfPlaintiff("");
    setNameOfDefendant("");
    setAddressOfDefendant("");
    setLawyerOfDefendant("");
    setCvrOfDefendant("");
    setHighCourtText("");
    setIsPlaintiffLawyerAppointed(false);
    setIsDefendantLawyerAppointed(false);
    setNumberOfJudges(null);
    setSelectedStartDate(undefined);
    setAddTimeline(false);
    setSelectedImage("");
    setSelectedPdf("");
    setPrintBothSide(false);
    setSelectedLogoPosition("");
    setShowAdvancedForm(false);
    setShowCaseInfoForm(false);
  };

  const checkMergeProgress = (requestId) => {
    // TODO: better handling
    mergeProgressRequest(requestId, (success, response) => {
      if (success) {
        if (!response?.status) {
          setOverlay({
            show: true,
            content: (
              <ErrorPopup
                text={"Der opstod en fejl under oprettelse af ekstrakt"}
                subtext={response ? response : ""}
                onClose={() => {
                  setOverlay({ show: false, content: <></> });
                }}
              />
            ),
          });
        } else {
          setMergeProgress(response);

          if (
            (response?.status === "Pending" ||
              response?.status === "InProgress") &&
            !response?.token
          ) {
            setTimeout(() => {
              checkMergeProgress(requestId);
            }, 1000);
          }
        }
      } else {
        setOverlay({
          show: true,
          content: (
            <ErrorPopup
              text={"Der opstod en fejl under oprettelse af ekstrakt"}
              subtext={response ? response : ""}
              onClose={() => {
                setOverlay({ show: false, content: <></> });
              }}
            />
          ),
        });
      }
    });
  };

  const margeDocuments = (mergeUploadKey, headers) => {
    if (!validateForm()) {
      return;
    }

    let mappedHeaderFiles = headers.map((header) => ({
      header: {
        name: header.id,
        group: header.group,
        order: header.order,
        isMandatory: header.isMandatory,
        isMultiple: header.isMultiple,
        isSortable: header.isSortable,
      },
      material: header.usedFiles.map((file) => {
        return {
          id: file.id,
          name: header.isMultiple === true ? file.name : header.id,
          date: !header.isMultiple ? null : getUTCDateTime(new Date(file.date)),
          content: file.content,
        };
      }),
    }));

    let model = {
      id: createExtractId,
      path: mergeUploadKey,
      type: selectedCourt,
      caseInfo: {
        courtNo: courtNumber,
        plaintiffName: nameOfPlaintiff,
        plaintiffCVR: cvrOfPlaintiff,
        plaintiffAddress: addressOfPlaintiff,
        plaintiffsLawyer: lawyerOfPlaintiff,
        defendantName: nameOfDefendant,
        defendantAddress: addressOfDefendant,
        defendantsLawyer: lawyerOfDefendant,
        defendantCVR: cvrOfDefendant,
        trialDateStart: selectedStartDate
          ? getUTCDateTime(new Date(selectedStartDate))
          : null,
        numberOfJudges: numberOfJudges,
        isPlaintiffLawyerAppointed: isPlaintiffLawyerAppointed,
        isDefendantLawyerAppointed: isDefendantLawyerAppointed,
        highCourtText: highCourtText,
        companyLogo: selectedImage?.content ? selectedImage.content : null,
        letterhead: selectedPdf?.content ? selectedPdf.content : null,
        printBothSide: printBothSide,
        addTimeline: addTimeline,
        logoPosition: selectedLogoPosition?.key ? selectedLogoPosition.key : "TopRight",
      },
      documentation: mappedHeaderFiles,
    };
    margeDocumentsRequest(model, (success, response) => {
      if (success) {
        addToSession("extract_in_progress", response);
        setMergeProgress(null);
        checkMergeProgress(response);
      } else {
        setOverlay({
          show: true,
          content: (
            <ErrorPopup
              text={"Der opstod en fejl under oprettelse af ekstrakt"}
              subtext={response ? response : ""}
              onClose={() => {
                setOverlay({ show: false, content: <></> });
              }}
            />
          ),
        });
      }
    });
  };

  const getHeaders = (type, callback) => {
    getHeadersRequest(type, (success, response) => {
      if (success) {
        let headers = response.map((header) => ({
          id: header.name,
          group: header.group,
          order: header.order,
          usedFiles: [],
          isMultiple: header.isMultiple,
          isMandatory: header.isMandatory,
          isSortable: header.isSortable,
        }));

        callback(true, headers);
      } else {
        callback(false, null);
      }
    });
  };

  const validatePdfFile = (validateHeaders, blob, callback) => {
    validatePdfFileRequest(validateHeaders, blob, (success, response) => {
      callback(success, response);
    });
  };

  const getLogoPositions = () => {
    getLogoPositionsRequest((success, data) => {
      if (success) {
        const logoIcons = [
          "content-alignTopCenterIcon",
          "content-alignTopLeftIcon",
          "content-alignTopRightIcon",
        ];

        const extendedData = data.map((item, index) => {
          return {
            ...item,
            icon: logoIcons[index],
          };
        });

        setLogoPositions(extendedData);

        if (extractedData) {
          setSelectedLogoPosition(
            data?.find(
              (element) => element.key === extractedData.caseInfo.logoPosition
            )
          );
        }
      } else {
      }
    });
  };

  useEffect(() => {
    let extractInProgressRequestId = getFromSession("extract_in_progress");
    if (
      extractInProgressRequestId !== undefined &&
      extractInProgressRequestId !== null
    ) {
      checkMergeProgress(extractInProgressRequestId);
    }

    let caseInfoFormData = getFromSession("case_info_form");
    let advancedFormData = getFromSession("advanced_form");
    if (
      (caseInfoFormData !== undefined && caseInfoFormData !== null) ||
      (advancedFormData !== undefined && advancedFormData !== null)
    ) {
      setShowCaseInfoForm(caseInfoFormData?.caseInfoFormOpened);
      setCourtNumber(caseInfoFormData?.courtNumber);
      setNameOfPlaintiff(caseInfoFormData?.nameOfPlaintiff);
      setCvrOfPlaintiff(caseInfoFormData?.cvrOfPlaintiff);
      setAddressOfPlaintiff(caseInfoFormData?.addressOfPlaintiff);
      setLawyerOfPlaintiff(caseInfoFormData?.lawyerOfPlaintiff);
      setNameOfDefendant(caseInfoFormData?.nameOfDefendant);
      setAddressOfDefendant(caseInfoFormData?.addressOfDefendant);
      setLawyerOfDefendant(caseInfoFormData?.lawyerOfDefendant);
      setCvrOfDefendant(caseInfoFormData?.cvrOfDefendant);
      setHighCourtText(caseInfoFormData?.highCourtText);
      setIsPlaintiffLawyerAppointed(
        caseInfoFormData?.isPlaintiffLawyerAppointed
      );
      setIsDefendantLawyerAppointed(
        caseInfoFormData?.isDefendantLawyerAppointed
      );
      setNumberOfJudges(caseInfoFormData?.numberOfJudges);
      setSelectedStartDate(caseInfoFormData?.selectedStartDate);

      setShowAdvancedForm(advancedFormData?.advancedFormOpened);
      setSelectedImage(advancedFormData?.selectedImage);
      setSelectedLogoPosition(advancedFormData?.selectedLogoPosition);
      setSelectedPdf(advancedFormData?.selectedPdf);
      setAddTimeline(advancedFormData?.addTimeline);
      setPrintBothSide(advancedFormData?.printBothSide);
    }
    if (currentChunkIndex !== null) {
      extractPDFFile();
    }
  }, [currentChunkIndex, uploadFile]);

  const readAndUploadCurrentChunk = (blob) => {
    setOverlayLock(true);
    var uploadHeaders = {
      "Content-Type": "application/octet-stream",
      Accept: "application/json",
      "NemEkstrakt-FileUpload-Key": uploadKey ?? null,
      "NemEkstrakt-FileUpload-FileName": uploadFile.name,
      "NemEkstrakt-FileUpload-Current": currentChunkIndex,
      "NemEkstrakt-FileUpload-Total": Math.ceil(uploadFile.size / chunkSize),
      "NemEkstrakt-FileUpload-Size": chunkSize,
    };

    if (currentChunkIndex === 0) {
      setProcessing(null);
    }

    if (currentChunkIndex === Math.ceil(uploadFile.size / chunkSize) - 1) {
      setCurrentChunkIndex(null);
      setProcessing(true);
    }

    extractDataFromPDFRequest(blob, uploadHeaders, (success, response) => {
      if (success) {
        setProcessing(null);

        if (currentChunkIndex === Math.ceil(uploadFile.size / chunkSize) - 1) {
          setCurrentChunkIndex(null);
          setUploadKey(response.path);
          clearSessionStorage();
          setUploadSuccess(true);
          preloadHomeview(response);
          setOverlayLock(false);
        } else {
          setCurrentChunkIndex((prev) => prev + 1);
          setUploadKey(response.key);
        }
      } else {
        clearSessionStorage();
        setUploadSuccess(false);
        setOverlayLock(false);
      }
    });
  };

  const extractPDFFile = () => {
    if (!uploadFile) {
      return;
    }
    const from = currentChunkIndex * chunkSize;
    const to = from + chunkSize;
    const blob = uploadFile.slice(from, to);
    readAndUploadCurrentChunk(blob);
  };

  const editExtractedFile = (headers) => {
    if (!validateForm()) {
      return;
    }

    let mappedHeaderFiles = headers.map((header) => ({
      header: {
        name: header.id,
        group: header.group,
        order: header.order,
        isMandatory: header.isMandatory,
        isMultiple: header.isMultiple,
        isSortable: header.isSortable,
      },
      material: header.usedFiles.map((file) => ({
        id: file.id,
        name: header.isMultiple === true ? file.name : header.id,
        date: !header.isMultiple ? null : file.date,
        content: file.content !== "" ? file.content : null,
      })),
    }));

    let model = {
      id: extractId,
      editKey: editKey,
      path: path,
      name: uploadFile.name,
      type: selectedCourt,
      caseInfo: {
        courtNo: courtNumber,
        plaintiffName: nameOfPlaintiff,
        plaintiffCVR: cvrOfPlaintiff,
        plaintiffAddress: addressOfPlaintiff,
        plaintiffsLawyer: lawyerOfPlaintiff,
        defendantName: nameOfDefendant,
        defendantAddress: addressOfDefendant,
        defendantsLawyer: lawyerOfDefendant,
        defendantCVR: cvrOfDefendant,
        numberOfJudges: numberOfJudges,
        isPlaintiffLawyerAppointed: isPlaintiffLawyerAppointed,
        isDefendantLawyerAppointed: isDefendantLawyerAppointed,
        trialDateStart: selectedStartDate,
        highCourtText: highCourtText,
        companyLogo: selectedImage.content,
        letterhead: selectedPdf.content,
        printBothSide: printBothSide,
        addTimeline: addTimeline,
        logoPosition: selectedLogoPosition.key,
      },
      documentation: mappedHeaderFiles,
    };
    setOverlay({ show: true, content: <LoadingPopup /> });
    editExtractedFileRequest(model, (success, response) => {
      if (success) {
        addToSession("extract_in_progress", response);
        setMergeProgress(null);
        checkMergeProgress(response);
      } else {
        setOverlay({
          show: true,
          content: (
            <ErrorPopup
              text={"Der opstod en fejl under redigering af ekstrakt"}
              subtext={response ? response : ""}
              onClose={() => {
                setOverlay({ show: false, content: <></> });
              }}
            />
          ),
        });
      }
    });
  };

  const preloadHomeview = (data) => {
    setExtractedData(data);
    setExtractId(data.id);
    setEditKey(data.editKey);
    setPath(data.path);

    setSelectedCourt(data.type);
    setCourtNumber(data.caseInfo.courtNo);
    setNameOfPlaintiff(data.caseInfo.plaintiffName);
    setCvrOfPlaintiff(data.caseInfo.plaintiffCVR);
    setAddressOfPlaintiff(data.caseInfo.plaintiffAddress);
    setLawyerOfPlaintiff(data.caseInfo.plaintiffsLawyer);
    setNameOfDefendant(data.caseInfo.defendantName);
    setAddressOfDefendant(data.caseInfo.defendantAddress);
    setLawyerOfDefendant(data.caseInfo.defendantsLawyer);
    setCvrOfDefendant(data.caseInfo.defendantCVR);
    setHighCourtText(data.caseInfo.highCourtText);
    setNumberOfJudges(data.caseInfo.numberOfJudges);
    setIsPlaintiffLawyerAppointed(data.caseInfo.isPlaintiffLawyerAppointed);
    setIsDefendantLawyerAppointed(data.caseInfo.isDefendantLawyerAppointed);

    if (data.caseInfo.trialDateStart)
      setSelectedStartDate(new Date(data.caseInfo.trialDateStart));

    if (data.caseInfo.companyLogo) {
      setSelectedImage({ name: "image", content: data.caseInfo.companyLogo });
    }

    if (data.caseInfo.letterhead) {
      setSelectedPdf({
        name: "Brevpapir.pdf",
        content: data.caseInfo.letterhead,
      });
    }

    setAddTimeline(data.caseInfo.addTimeline);
    setPrintBothSide(data.caseInfo.printBothSide);
    setSelectedLogoPosition(
      logoPositions.find(
        (position) => position.key === data.caseInfo.logoPosition
      )
    );

    sessionStorage.removeItem("court_type");
    addToSession("court_type", { type: data.type });

    sessionStorage.removeItem("case_info_form");
    addToSession("case_info_form", {
      caseInfoFormOpened: showCaseInfoForm,
      courtNumber: data.caseInfo.courtNo,
      nameOfPlaintiff: data.caseInfo.plaintiffName,
      cvrOfPlaintiff: data.caseInfo.plaintiffCVR,
      addressOfPlaintiff: data.caseInfo.plaintiffAddress,
      lawyerOfPlaintiff: data.caseInfo.plaintiffsLawyer,
      nameOfDefendant: data.caseInfo.defendantName,
      addressOfDefendant: data.caseInfo.defendantAddress,
      lawyerOfDefendant: data.caseInfo.defendantsLawyer,
      highCourtText: data.caseInfo.highCourtText,
      cvrOfDefendant: data.caseInfo.defendantCVR,
      isPlaintiffLawyerAppointed: data.caseInfo.isPlaintiffLawyerAppointed,
      isDefendantLawyerAppointed: data.caseInfo.isDefendantLawyerAppointed,
      numberOfJudges: data.caseInfo.numberOfJudges,
      selectedStartDate: data.caseInfo.selectedStartDate,
    });
    sessionStorage.removeItem("advanced_form");
    addToSession("advanced_form", {
      advancedFormOpened: showAdvancedForm,
      selectedImage: data.caseInfo.companyLogo,
      selectedLogoPosition: data.caseInfo.logoPosition,
      selectedPdf: data.caseInfo.letterhead,
      addTimeline: data.caseInfo.addTimeline,
      printBothSide: data.caseInfo.printBothSide,
    });
  };

  return (
    <extractContext.Provider
      value={{
        errorCourtNumber,
        errorNameOfPlaintiff,
        errorAddressOfPlaintiff,
        errorLawyerOfPlaintiff,
        errorCvrOfPlaintiff,
        errorNameOfDefendant,
        errorAddressOfDefendant,
        errorLawyerOfDefendant,
        errorHighCourtText,
        errorCvrOfDefendant,

        dataAndTimeError,
        companyLogoError,
        ////
        headers,
        setHeaders,
        userInfo,
        setUserInfo,
        courtNumber,
        setCourtNumber,
        nameOfPlaintiff,
        setNameOfPlaintiff,
        cvrOfPlaintiff,
        setCvrOfPlaintiff,
        addressOfPlaintiff,
        setAddressOfPlaintiff,
        lawyerOfPlaintiff,
        setLawyerOfPlaintiff,
        nameOfDefendant,
        setNameOfDefendant,
        addressOfDefendant,
        setAddressOfDefendant,
        lawyerOfDefendant,
        setLawyerOfDefendant,
        highCourtText,
        setHighCourtText,
        cvrOfDefendant,
        setCvrOfDefendant,
        isPlaintiffLawyerAppointed,
        setIsPlaintiffLawyerAppointed,
        isDefendantLawyerAppointed,
        setIsDefendantLawyerAppointed,
        numberOfJudges,
        setNumberOfJudges,

        ////
        uploadKey,
        setUploadKey,
        chunkSize,
        uploadFile,
        setUploadFile,
        currentChunkIndex,
        setCurrentChunkIndex,
        processing,
        uploadSuccess,

        selectedLogoPosition,
        setSelectedLogoPosition,
        logoPositions,
        ////

        hasChanges,
        showCaseInfoForm,
        setShowCaseInfoForm,
        showAdvancedForm,
        setShowAdvancedForm,
        clearFields,
        validateForm,
        clearErrorStates,
        clearSessionStorage,

        margeDocuments,
        getHeaders,
        validatePdfFile,
        getLogoPositions,
        selectedCourt,
        setSelectedCourt,
        addTimeline,
        setAddTimeline,
        selectedImage,
        setSelectedImage,
        selectedPdf,
        setSelectedPdf,
        selectedStartDate,
        setSelectedStartDate,
        extractPDFFile,
        extractedData,
        editExtractedFile,
        printBothSide,
        setPrintBothSide,
        //
        blockTypeSwitch,
        setBlockTypeSwitch,
        setIsExtractActive,
      }}
    >
      <Outlet context={[showCourts, setShowCourts]} />
    </extractContext.Provider>
  );
};
