import { formatDate } from "__utilities/functions/public";

export default function BillingHistory({billingHistory, downloadInvoicePdf}) {
  return (
    <table className="border border-[#EAECF0] text-sm">
      <thead>
        <tr className="bg-[#F0F3F5] h-11">
          <th className="pl-3 tall:pl-6">Faktura</th>
          <th className="pl-3 tall:pl-6">E-mail</th>
          <th className="pl-3 tall:pl-6">Status</th>
          <th className="pl-3 tall:pl-6">Abonnement fra</th>
          <th className="pl-3 tall:pl-6">Abonnement til</th>
          <th className="pl-3 tall:pl-6">Licens</th>
          <th className="pl-3 tall:pl-6">Beløb</th>
          <th className="pr-2"></th>
        </tr>
      </thead>
      <tbody>
        {billingHistory.map((bill, index) => (
          <tr key={bill.transactionId} className={`${index % 2 === 1 && "bg-[#F6F5F3]"} h-16`}>
            <td className="pl-3 tall:pl-6">{bill?.invoiceNumber}</td>
            <td className="pl-3 tall:pl-6">{bill?.billingEmail}</td>
            <td className="pl-3 tall:pl-6">
              {bill?.status === "approved" ? (
                <div className="flex flex-row w-fit justify-center items-center gap-x-2 px-3 py-1 rounded-full border border-primaryGreen border-opacity-20 bg-[#ECFDF3] font-semibold text-[#067647]">
                  <img className="content-checkmarkIcon" alt="" />
                  <p>{bill.status}</p>
                </div>
              ) : (
                <div className="flex flex-row w-fit justify-center items-center gap-x-2 px-3 py-1 rounded-full border border-primaryRed bg-primaryBrown bg-opacity-[0.15] font-semibold text-primaryRed">
                  <img className="content-cancelledIcon scale-150" alt="" />
                  <p>{bill.status}</p>
                </div>
              )}
            </td>
            <td className="pl-3 tall:pl-6">{formatDate(bill?.periodFrom)}</td>
            <td className="pl-3 tall:pl-6">
              {formatDate(bill?.periodTo)}
            </td>
            <td className="pl-3 tall:pl-6">{bill?.license}</td>
            <td className="pl-3 tall:pl-6">DKK {bill?.amount.toLocaleString("da-DK", { minimumFractionDigits: 0 })}</td>
            <td className="pr-2">
              {bill.downloadToken && (<img onClick={() => downloadInvoicePdf(bill.downloadToken)} className="content-downloadIcon cursor-pointer" alt="" />)}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
