import usePayment from "_data/hooks/payment/usePayment";
import { getUserInfo } from "msal/MsalAuthProvider";
import { useContext, useEffect, useState } from "react";
import UserCard from "./UserCard";
import { useNavigate } from "react-router-dom";
import { overlayContext } from "_data/context/overlay";
import SubscriptionButton from "./Subscription/SubscriptionButton";
import SubscriptionUpgradePopup from "__utilities/components/SubscriptionUpgradePopup";
import { useMsal } from "@azure/msal-react";
import { navigateTo } from "_navigation/manager";

export default function Header({
  showDropdown,
  setShowDropdown,
  userInfo,
  setUserInfo,
  isExtractActive,
  setIsExtractActive,
  navigateToHome,
}) {
  let currentUser = getUserInfo();
  const { setOverlay } = useContext(overlayContext);
  const { instance } = useMsal();
  const { getUserInfoData } = usePayment();
  const navigate = useNavigate();
  useEffect(() => {
    refreshUserInfo();
  }, []);
  const [loading, setLoading] = useState(false);

  const signOut = () => {
    navigateTo(
      setOverlay,
      isExtractActive,
      setIsExtractActive,
      () => {
        instance.logout().catch((error) => {
          console.log(error);
        });
      }
    );
  };

  const refreshUserInfo = async () => {
    setLoading(true);
    await getUserInfoData((success, userInfo) => {
      if (success) {
        setUserInfo(userInfo);
      }
    });
    setLoading(false);
  };

  return (
    <div
      className="w-full flex flex-row justify-between items-center border-b-[#C5C3C3] border-b-[1px] px-[30px]"
      onClick={() => setShowDropdown(false)}
    >
      <img
        className="content-logoIcon m-[10px] cursor-pointer"
        alt=""
        onClick={navigateToHome}
      />
      <div className="flex flex-row">
        {(userInfo?.licenseHasExpired ||
          userInfo?.subscriptionStatus !== "Active") &&
          userInfo?.hasOwnProperty("licenseHasExpired") && (
            <SubscriptionButton
              onClick={() => {
                setShowDropdown(false);
                setOverlay({
                  show: true,
                  content: (
                    <SubscriptionUpgradePopup
                      hideOverlay={() =>
                        setOverlay({ show: false, content: <></> })
                      }
                      userInfo={userInfo}
                      refreshUser={() => refreshUserInfo()}
                    />
                  ),
                });
              }}
            />
          )}
        <UserCard
          currentUser={currentUser}
          userInfo={userInfo}
          onClick={(e) => {
            e.stopPropagation();
            setShowDropdown(!showDropdown);
          }}
          showDropdown={showDropdown}
          setShowDropdown={setShowDropdown}
          navigateToMyAccount={() => {
            navigateTo(
              setOverlay,
              isExtractActive,
              setIsExtractActive,
              () => {
                setShowDropdown(false);
                navigate("/account");
              }
            );
          }}
          signOut={() => signOut()}
        />
      </div>
    </div>
  );
}
