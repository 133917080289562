import { v4 as uuid } from "uuid";

export const getUuid = () => {
  return uuid();
};

export const fileToBase64 = (file, callback, type = "application/pdf") => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    const removedPrefix = reader.result.replace(`data:${type};base64,`, "");
    callback(null, removedPrefix);
  };
  reader.onerror = function (error) {
    callback(error, null);
  };
};

export const downloadPDFFile = (href) => {
  const downloadLink = document.createElement("a");
  downloadLink.href = href;
  downloadLink.target = "_blank";
  downloadLink.click();
};

export const getUTCDateTime = (date) => {
  const localTimezoneOffset = date.getTimezoneOffset() * 60000; // get the time zone offset in milliseconds
  const utcTime = date.getTime() - localTimezoneOffset; // subtract the offset to get the UTC time

  // create a new Date object with the UTC time
  const utcDate = new Date(utcTime);
  return utcDate;
};

export const parseJwt = (token) => {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};

export const formatDate = (inputDate) => {
  if (!inputDate) {
    return null;
  }

  const date = new Date(inputDate);
  const months = [
    "januar",
    "februar",
    "marts",
    "april",
    "maj",
    "juni",
    "juli",
    "august",
    "september",
    "oktober",
    "november",
    "december",
  ];
  
  const day = date.getDate();
  const monthIndex = date.getMonth();
  const month = months[monthIndex];
  const year = date.getFullYear();
  
  const formattedDate = `${day}. ${month} ${year}`;

  return formattedDate;
};
