export default function SubscriptionButton({onClick}) {
  return (
    <div className="flex flex-row justify-center items-center px-5 gap-x-2 cursor-pointer m-[10px] select-none bg-[#F0F3F5]" onClick={onClick}>
      <div className="flex w-8 h-8 justify-center items-center bg-primaryBlue">
        <img className="content-subscriptionIcon" alt="" />
      </div>
      <p className="font-latoBold text-standardSmall text-primaryBlue">
        Opgradér nu
      </p>
    </div>
  );
}
