export default function UserCard({
  currentUser,
  userInfo,
  onClick,
  showDropdown,
  navigateToMyAccount,
  signOut,
}) {
  const userInitials = currentUser ? currentUser?.first_name[0] + currentUser?.last_name[0] : "";

  return (
    <div className="flex flex-col items-end cursor-pointer m-[10px] select-none">
      <div
        className="flex flex-row gap-x-2 items-center relative"
        onClick={onClick}
      >
        <div className="flex flex-col min-w-[120px] text-right">
          <p className="text-standardLarge text-primaryBlue font-latoBold">
            {userInfo?.displayName}
          </p>
          <p className="text-standardXSmall text-textExtraGray font-latoBold">
            {userInfo?.executionLicense}
          </p>
        </div>
        <div className="flex w-11 h-11 border border-borderGray bg-bloorGreen rounded-full justify-center items-center">
          <p className="text-standardLarge text-primaryBlue font-latoBold pt-[2px]">
            {userInitials}
          </p>
        </div>
      </div>
      {showDropdown && (
        <div className="flex flex-row w-60 justify-between items-center  border border-lightGrey bg-white z-50 text-left absolute translate-y-12">
          <div className="flex flex-col w-full">
            <div
              className="flex flex-row w-full justify-between items-center p-3"
              onClick={navigateToMyAccount}
            >
              <p className="text-standard text-primaryBlue font-latoBold">
                Min konto
              </p>
            </div>
            <div className="h-[1px] bg-lightGrey w-full"></div>
            <div
              className="flex flex-row w-full justify-between items-center p-3"
              onClick={signOut}
            >
              <p className="text-standard text-primaryBlue font-latoBold">
                Log ud
              </p>
              <div className="flex w-8 h-8 justify-center items-center bg-lightBrown">
                <img className="content-signOutIcon" alt="" />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
