import React from "react";
import MainView from "app/MainView";
import TermsView from "app/Terms/TermsView";
import { ExtractProvider } from "_data/context/extract";
import ChooseTypeView from "app/ChooseTypeView";
import HomepageView from "app/Homepage/HomepageView";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import OnPaymentSuccessView from "app/Payment/OnPaymentSuccessView";
import OnPaymentCancelView from "app/Payment/OnPaymentCancelView";
import AccountView from "./Account/AccountView";
import HelperProvider from "_data/context/helper";
import { BillingView } from "./Billing/BillingView";
import OnPaymentRenewView from "./Payment/OnPaymentRenewSuccess";

export default function Routing() {
  const router = createBrowserRouter([
    {
      element: <MainView />,
      children: [
        {
          element: <ExtractProvider />,
          children: [
            {
              path: "/",
              element: (
                <HelperProvider>
                  <ChooseTypeView />
                </HelperProvider>
              ),
            },
          ],
        },
        {
          path: "terms",
          element: <TermsView />,
        },
        {
          element: <ExtractProvider />,
          children: [
            {
              path: "home",
              element: (
                <HelperProvider>
                  <HomepageView />
                </HelperProvider>
              ),
            },
          ],
        },
        {
          path: "billing",
          element: <BillingView />,
        },
        {
          path: "account",
          element: <AccountView />,
        },
        {
          path: "/payment/success",
          element: <OnPaymentRenewView />,
        },
        {
          path: "/payment/:id/success",
          element: <OnPaymentSuccessView />,
        },
        {
          path: "/payment/:id/cancel",
          element: <OnPaymentCancelView />,
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
}
