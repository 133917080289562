import { formatDate } from "__utilities/functions/public";
import { overlayContext } from "_data/context/overlay";
import usePayment from "_data/hooks/payment/usePayment";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import AccountViewButtons from "./AccountViewButtons";
import CustomButton from "__utilities/components/CustomButton";
import ConfirmationPopup from "__utilities/components/ConfirmationPopup";
import { deleteUserRequest } from "_data/services/auth/service";
import { useMsal } from "@azure/msal-react";
import { clearUserData } from "msal/MsalAuthProvider";
import BillingForm from "_features/BillingForm";
import BillingHistory from "_features/BillingHistory";
import { downloadPDFFile } from "__utilities/functions/public";
import { AppConfig } from "AppConfig";

export default function AccountView() {
  const { setOverlay } = useContext(overlayContext);
  const { userInfo, setUserInfo } = useOutletContext();
  const [billingHistory, setBillingHistory] = useState([]);
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [showBillingDetails, setShowBillingDetails] = useState(false);
  const expirationDate = `${formatDate(userInfo?.licenseExpiration)}`;
  const subscriberSince = `${formatDate(userInfo?.subscriberSince)}`;
  const memberSince = `${formatDate(userInfo?.memberSince)}`;
  const navigate = useNavigate();
  const {
    getUserInfoData,
    getBillingHistory,
    getInitialBillingAddress,
    sendBillingAddress,
    unsubscribePayment,
    switchPayment,
    getSubscriptionTypes,
  } = usePayment();
  const { instance } = useMsal();

  const signOut = () => {
    instance.logout().catch((error) => {
      console.log(error);
    });
  };

  const getSubscriptionTypesData = async () => {
    await getSubscriptionTypes((success, response) => {
      if (success) {
        setSubscriptionData(response);
      }
    });
  };

  const switchSubscription = () => {
    userInfo?.subscriptionType &&
      (userInfo?.subscriptionType === "Year"
        ? switchPayment("Month", () => refreshUserInfo())
        : switchPayment("Year", () => refreshUserInfo()));
    setOverlay({ show: false, content: <></> });
  };

  const getBillingHistoryData = async () => {
    await getBillingHistory((success, response) => {
      if (success) {
        setBillingHistory(response);
      }
    });
  };

  const refreshUserInfo = async () => {
    await getUserInfoData((success, userInfoResponse) => {
      if (success) {
        setUserInfo(userInfoResponse);
      }
    });
  };

  const downloadInvoicePdf = async (token) => {
    downloadPDFFile(
      `${AppConfig.SERVICE_BASE_URL}/api/v1/payment/billinghistory/download/${token}`
    );
  };

  const deleteUser = async () => {
    await deleteUserRequest(() => {
      clearUserData();
      signOut();
    });
  };

  useEffect(() => {
    getSubscriptionTypesData();
    getBillingHistoryData();
  }, []);

  return (
    <div className="flex flex-col w-full h-full justify-start items-start gap-y-6 p-5 tall:p-11">
      <div className="flex flex-row w-full justify-between mb-12">
        <p className="text-primaryBlue  text-xl tall:text-[24px] font-latoBold">
          Min konto
        </p>
        <CustomButton
          text={"Slet konto"}
          onClick={() =>
            setOverlay({
              show: true,
              content: (
                <ConfirmationPopup
                  text={
                    "Din konto vil blive slettet permanent. Vil du fortsætte?"
                  }
                  cancelPopup={() =>
                    setOverlay({ show: false, content: <></> })
                  }
                  confirmContinue={() => deleteUser()}
                />
              ),
            })
          }
          styles={"w-[10%]"}
        />
      </div>
      <div className="flex flex-row w-full justify-start gap-x-10 tall:gap-x-20">
        <div className="flex flex-col w-1/5">
          <p className="text-primaryBlue text-standardXLarge tall:text-2xl font-latoBold">
            Abonnement:
          </p>
          {userInfo?.memberSince && (
            <p className="text-textLightGray text-xs tall:text-base font-latoBold">
              {`Medlem siden ${memberSince}`}
            </p>
          )}
          <p className="text-textLightGray text-xs tall:text-base font-latoBold">
            {userInfo?.subscriberSince
              ? `Abonnent siden ${subscriberSince}`
              : "Ikke abonneret"}
          </p>
        </div>
        {userInfo?.subscriptionType && (
          <div className="flex flex-col w-full h-[300px] bg-[#F0F3F5] border border-[#EAECF0]">
            <div className="flex flex-row w-full h-3/5 items-center">
              <div className="flex flex-col w-full gap-y-2 p-5">
                <p className="text-[24px] text-primaryBlue font-latoBold">
                  {userInfo?.executionLicense}
                </p>
                <div className="flex flex-row gap-x-2">
                  <p className="text-standardLarge text-textGray font-latoLight">
                    {userInfo?.licenseHasExpired
                      ? "Dit abonnement er udløbet"
                      : userInfo?.subscriptionStatus === "Cancelled" || userInfo?.subscriptionStatus === "Deleted"
                      ? "Dit abonnement udløber"
                      : "Dit abonnement fornys automatisk den"}
                  </p>
                  <p className="text-standardLarge text-primaryBlue font-latoBold">
                    {expirationDate}
                  </p>
                </div>
              </div>
              <div className="flex flex-row h-full px-5 py-11 font-latoBold text-primaryBlue">
                <div className="flex flex-col h-full justify-start items-start text-lg tall:text-[24px] translate-y-5 tall:translate-y-4">
                  {subscriptionData?.find((el) => el.type === userInfo?.subscriptionType)?.currency}
                </div>
                <div className="flex flex-col h-full justify-center items-center text-5xl tall:text-[60px]">
                  {subscriptionData?.find((el) => el.type === userInfo?.subscriptionType)?.baseAmount.toLocaleString("da-DK", { minimumFractionDigits: 0 })}
                </div>
                <div className="flex flex-col h-full justify-end items-end text-sm tall:text-standardLarge -translate-y-6 tall:-translate-y-4">
                  {userInfo?.subscriptionType === "Year"
                    ? <span>per&nbsp;år</span>
                    : <span>om&nbsp;måneden</span>}
                </div>
              </div>
            </div>

            <AccountViewButtons
              userInfo={userInfo}
              refreshUserInfo={refreshUserInfo}
              setOverlay={setOverlay}
              unsubscribePayment={unsubscribePayment}
              switchSubscription={switchSubscription}
            />
          </div>
        )}
      </div>

      <div className="flex flex-row w-full justify-start gap-x-10 tall:gap-x-20 mt-12 pb-10">
        <div className="flex flex-col w-1/5 gap-y-4">
          <p className="text-primaryBlue text-standardXLarge tall:text-2xl font-latoBold">
            Fakturering:
          </p>
          <div className="flex flex-col">
            <p
              onClick={() => setShowBillingDetails(false)}
              className={`${
                !showBillingDetails ? "text-primaryBlue" : "text-textXLightGray"
              } text-standardSmall tall:text-lg font-latoBold cursor-pointer`}
            >
              Faktureringshistorik
            </p>
            <p
              onClick={() => setShowBillingDetails(true)}
              className={`${
                showBillingDetails ? "text-primaryBlue" : "text-textXLightGray"
              } text-standardSmall tall:text-lg font-latoBold cursor-pointer`}
            >
              Faktureringsdetaljer
            </p>
          </div>
        </div>

        <div className="flex flex-col w-full h-full">
          {!showBillingDetails ? (
            <BillingHistory
              billingHistory={billingHistory}
              downloadInvoicePdf={downloadInvoicePdf}
            />
          ) : (
            <div className="flex flex-col w-1/2 h-full">
              <BillingForm
                getInitialBillingAddress={getInitialBillingAddress}
                sendBillingAddress={sendBillingAddress}
                initialEmail={userInfo.email}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
