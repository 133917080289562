export const AppConfig = {
    SERVICE_BASE_URL: process.env.REACT_APP_SERVICE_BASE_URL,
    SERVICE_HEADERS: { "Content-Type": "application/json" },

    AUTH_ENABLED: process.env.REACT_APP_AUTH_ENABLED === 'true',
    AUTH_STORE_IN_COOKIE: process.env.REACT_APP_AUTH_STORE_IN_COOKIE === 'true',
    AUTH_CLIENT_ID: process.env.REACT_APP_AUTH_CLIENT_ID,
    AUTH_TENANT_ID: process.env.REACT_APP_AUTH_TENANT_ID,
    AUTH_REDIRECT_URL: process.env.REACT_APP_AUTH_REDIRECT_URL,
    AUTH_API_SCOPE: process.env.REACT_APP_AUTH_API_SCOPE,
    AUTH_DOMAIN_NAME: process.env.REACT_APP_AUTH_DOMAIN_NAME,
    AUTH_POLICY_SIGN_UP_SIGN_IN: process.env.REACT_APP_AUTH_POLICY_SIGN_UP_SIGN_IN,
    AUTH_POLICY_FORGOT_PASSWORD: process.env.REACT_APP_AUTH_POLICY_FORGOT_PASSWORD,
    AUTH_POLICY_EDIT_PROFILE: process.env.REACT_APP_AUTH_POLICY_EDIT_PROFILE,

    APP_WEBSITE_KONTAKT: process.env.REACT_APP_REDIRECT_TO_KONTAKT,
    APP_WEBSITE_HANDELSBETINGELSER: process.env.REACT_APP_REDIRECT_TO_HANDELSBETINGELSER,
    APP_WEBSITE_PRIVATLIVSPOLITIK: process.env.REACT_APP_REDIRECT_TO_PRIVATLIVSPOLITIK,
};