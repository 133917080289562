import Popup from "./Popup";
import CustomButton from "./CustomButton";
import { formatDate } from "__utilities/functions/public";

export default function SubscriptionSwitchPopup({ hideOverlay,switchSubscription, userInfo }) {
  const expiration = `${formatDate(userInfo?.licenseExpiration)}`;
  const subscriptionType = userInfo?.subscriptionType === 'Year' ? "månedlig" : "årlig"
  return (
    <Popup styles={"w-[50%]"}>
      <div className="flex flex-col w-full h-full justify-center items-center gap-y-4 p-5">
        <p className="text-center text-primaryBlue font-latoBold text-[22px]">
        Er du sikker på, du ønsker at skifte til {subscriptionType} betaling?
        </p>
        <p className="text-center font-normal text-textXLightGray">
          {userInfo?.subscriptionType === 'Year' ? 'Ved at gøre dette vil du blive opkrævet månedligt.' : 'Ved at skifte til årlig betaling får du 2 måneder gratis i et år. Beløbet opkræves en gang om året.' }
          <br />
          Dit abonnement vil automatisk fornyes den  {expiration}.
        </p>
        <div className="flex flex-row w-full justify-center gap-x-5">
          <CustomButton
            onClick={hideOverlay}
            text={` BEHOLD ${userInfo?.subscriptionType === 'Year' ? 'ÅRLIGT' : 'MÅNEDLIG'}`}
            styles={
              "w-1/3 border border-primaryBlue bg-[#FFFFFF] text-[#003057] font-latoBold text-standardXSmall"
            }
          />
          <CustomButton
            onClick={switchSubscription}
            text={`SKIFT TIL ${userInfo?.subscriptionType === 'Year' ? 'MÅNEDLIG' : 'ÅRLIGT'}`}
            styles={"w-1/3 text-standardXSmall font-latoBold"}
          />
        </div>
      </div>
    </Popup>
  );
}
