import { useEffect, useState } from "react";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import "__resources/styles/custom-toggle.css";
import usePayment from "_data/hooks/payment/usePayment";

export default function SubscriptionCard({
  isToggled = false,
  setIsToggled = () => {},
  styles,
  license,
  price,
  description,
  items,
  hasToggle = false,
  isButtonDisabled = false,
  buttonText,
  onClick,
}) {
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [subscriptionPrice, setSubscriptionPrice] = useState(price);
  const handleToggleChange = (event) => {
    const isChecked = event.target.checked;
    setIsToggled(isChecked);
    setSubscriptionPrice(isChecked ? "Year" : price);
  };

  const {
    getSubscriptionTypes,
  } = usePayment();
    
  const getSubscriptionTypesData = async () => {
    await getSubscriptionTypes((success, response) => {
      if (success) {
        setSubscriptionData(response);
      }
    });
  };

  useEffect(() => {
    setSubscriptionPrice(isToggled ? "Year" : price);
    getSubscriptionTypesData();
  }, []);

  return (
    <div
      className={`flex flex-col justify-center w-1/3 p-3 tall:p-8 rounded-lg ${styles}`}
    >
      <div className="flex flex-col w-full h-1/3">
        <div className="flex w-full justify-center items-center">
          <div className="flex justify-center items-center text-primaryBlue text-standardXSmall tall:text-[14px] font-latoBold bg-white px-4 py-2">
            <p>{license}</p>
          </div>
        </div>
        <div className="flex w-full h-16 justify-center items-center text-primaryBlue text-[22px] tall:text-[32px] tall:pt-4 first-letter font-latoBold !leading-none">
          {hasToggle ? (<p>{`${subscriptionData?.find((el) => el.type === subscriptionPrice)?.currency ?? "DKK"} ${subscriptionData?.find((el) => el.type === subscriptionPrice)?.baseAmount.toLocaleString("da-DK", { minimumFractionDigits: 0 }) ?? "1.500"} / ${subscriptionPrice === "Year" ? "år" : "måned" ?? "måned"}`}</p>) : <p>{subscriptionPrice}</p>}
        </div>
        {hasToggle && (
          <>
            <div className="flex flex-row gap-x-3 justify-center items-center mt-[-8px]">
              <span className="text-standardXSmall tall:text-standardSmall font-latoBold text-primaryBlue">
                (Excl. VAT)
              </span>
            </div>
            <div className="flex flex-row gap-x-3 justify-center items-center">
              <Toggle
                defaultChecked={isToggled}
                icons={false}
                onChange={handleToggleChange}
                disabled={isButtonDisabled}
              />
              <span className="text-standardXSmall tall:text-standardSmall font-latoBold text-primaryBlue">
                Årlig pris (spar 16 %)
              </span>
            </div>
          </>
        )}
        <div className="flex w-full h-28 justify-center items-center text-[10px] tall:text-standardSmall px-[20%] tall:px-[15%] text-center">
          <p>{description}</p>
        </div>
      </div>
      <div className="flex flex-col h-full tall:gap-y-3">
        {license !== "Freemium" && (
          <p className="text-textGray text-standardXSmall tall:text-standardSmall">
            Alt fra {license === "Premium" ? "Freemium" : "Premium"} samt:
          </p>
        )}
        {items.map((item, index) => (
          <div
            key={index}
            className="flex flex-row w-full justify-start items-center gap-x-2"
          >
            <img
              className="content-checkCircleIcon"
              alt=""
            />
            <p className="text-textGray text-standardXSmall tall:text-standardSmall">
              {item}
            </p>
          </div>
        ))}
      </div>
      {buttonText && (
        <div
          className={`flex flex-col w-full h-fit bg-primaryBlue text-white
             p-2 cursor-pointer text-standardXSmall tall:text-standardSmall font-latoBold uppercase justify-center items-center  ${
               isButtonDisabled &&
               "bg-lightGrey text-textXLightGray pointer-events-none select-none"
             }`}
          onClick={onClick}
        >
          {buttonText}
        </div>
      )}
    </div>
  );
}
