import React, { useState, useEffect } from "react";

import Cookies from "universal-cookie";
import { AppConfig } from "AppConfig";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "./authConfig";

import * as keys from "./keys";
import LoadingSpinner from "__utilities/components/LoadingSpinner/LoadingSpinner";
import { parseJwt } from "__utilities/functions/public";

const cookies = new Cookies();

function getAccessToken() {
  const token = AppConfig.AUTH_STORE_IN_COOKIE
    ? cookies.get(keys.AUTH_TOKEN_KEY)
    : localStorage.getItem(keys.AUTH_TOKEN_KEY);

  return token;
}

export function getRequestHeaders() {
  let headers = AppConfig.SERVICE_HEADERS;

  const token = getAccessToken();
  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }

  return headers;
}

export function getUserInfo() {
  const token = getAccessToken();

  const tokenPayload = token ? parseJwt(token) : null;

  return tokenPayload
    ? {
        display_name: tokenPayload["name"],
        first_name: tokenPayload["given_name"],
        last_name: tokenPayload["family_name"],
      }
    : null;
}

export function clearUserData() {
  cookies.remove(keys.AUTH_TOKEN_KEY);
  localStorage.removeItem(keys.AUTH_TOKEN_KEY);
}

const withAuth = (HocComponent) => (props) => {
  const { instance } = useMsal();

  const [state, setState] = useState({
    isAuthenticated: false,
    refreshTokenIntervalId: null,
    user: {},
    renewIframe: false,
    hasError: false,
    errorMessage: null,
  });

  const Authenticate = async (user) => {
    await GetAccessToken(loginRequest, user);

    clearInterval(state.refreshTokenIntervalId);
    var refreshTokenIntervalId = setInterval(async () => {

      let accounts = instance.getAllAccounts();
      let signedInAccount = accounts && accounts.length > 0 ? accounts[0] : null;
      
      if (AppConfig.AUTH_STORE_IN_COOKIE) {
        if (!cookies.get(keys.AUTH_TOKEN_KEY)) {
          await GetAccessToken(loginRequest, signedInAccount);
        }
      } else {
        const exparationDate = new Date(
          localStorage.getItem(keys.AUTH_TOKEN_EXPARATION_KEY)
        );
        if (!exparationDate || exparationDate < new Date()) {
          await GetAccessToken(loginRequest, signedInAccount);
        }
      }
    }, 60000);

    setState({
      ...state,
      isAuthenticated: true,
      refreshTokenIntervalId,
      user,
    });
  };

  const GetAccessToken = async (tokenRequest, account, save = true) => {
    const request = {
      ...tokenRequest,
      account: account,
    };

    var accessToken = null;
    try {
      accessToken = await instance.acquireTokenSilent(request);
    } catch (error) {
      console.log(error);
      try {
        accessToken = await instance.acquireTokenRedirect(request);
      } catch (errorPopup) {
        console.log(errorPopup);
      }
    }

    if (save) {
      // get token exparation date
      if (accessToken && accessToken.expiresOn) {
        const exparationDate = new Date(accessToken.expiresOn);
        exparationDate.setMinutes(accessToken.expiresOn.getMinutes() - 5);

        // get API access token
        if (AppConfig.AUTH_STORE_IN_COOKIE) {
          cookies.set(keys.AUTH_TOKEN_KEY, accessToken.accessToken, {
            path: "/",
            expires: exparationDate,
          });
        } else {
          localStorage.setItem(keys.AUTH_TOKEN_KEY, accessToken.accessToken);
          localStorage.setItem(keys.AUTH_TOKEN_EXPARATION_KEY, exparationDate);
        }
      } else if (accessToken && accessToken.idToken) {
        const exparationDate = new Date();
        exparationDate.setDate(exparationDate.getDate() + 1);
        exparationDate.setMinutes(exparationDate.getMinutes() - 5);

        // get API access token
        if (AppConfig.AUTH_STORE_IN_COOKIE) {
          cookies.set(keys.AUTH_TOKEN_KEY, accessToken.idToken, {
            path: "/",
            expires: exparationDate,
          });
        } else {
          localStorage.setItem(keys.AUTH_TOKEN_KEY, accessToken.idToken);
          localStorage.setItem(keys.AUTH_TOKEN_EXPARATION_KEY, exparationDate);
        }
      }
    }

    return accessToken;
  };

  const SignIn = async () => {
    await instance.handleRedirectPromise().catch((error) => {
      console.log(error);
    });

    const accounts = instance.getAllAccounts();
    if (accounts.length === 0) {
      await instance.loginRedirect(loginRequest).catch((e) => {
        console.error(e);

        setState({
          ...state,
          hasError: true,
          errorMessage: e.errorMessage,
        });
      });
    } else {
      await Authenticate(accounts[0]).catch((error) => {
        console.log(error);
      });
    }
  };

  useEffect(() => {
    SignIn();
  }, []);

  const onSignIn = () =>
    instance.loginRedirect({ loginRequest }).catch((error) => {
      console.log(error);
    });

  const onSignOut = () =>
    instance.logout().catch((error) => {
      console.log(error);
    });

  if (state.renewIframe) return <div>hidden renew iframe - not visible</div>;

  if (state.isAuthenticated)
    return (
      <HocComponent
        {...props}
        auth={state}
        // onSignIn={() => onSignIn()}
        // onSignOut={() => onSignOut()}
      />
    );

  if (state.hasError) return <div>{state.errorMessage}</div>;

  return (
    <div>
      <LoadingSpinner />
    </div>
  );
};

export default withAuth;
