import { AppConfig } from "AppConfig";
import { getRequestHeaders } from "msal/MsalAuthProvider";
import { createLog } from "_data/services/extract/service";

export const getBillingAddressRequest = (callback) => {
  fetch(`${AppConfig.SERVICE_BASE_URL}/api/v1/payment/billingaddress`, {
		method: "GET",
		headers: getRequestHeaders(),
	})
		.then((response) => response.json())
		.then((response) => {
			if (response.status >= 400) {
				callback(false, null);
				createLog("Error", `Get Billing Address Status: ${response.status}`);
				return;
			}
			callback(true, response);
		})
		.catch((error) => {
			callback(false, null);
			createLog("Error", `Get Billing Address: ${error}`);
		});
};

export const setBillingAddressRequest = (body, callback) => {
  fetch(`${AppConfig.SERVICE_BASE_URL}/api/v1/payment/billingaddress`, {
		method: "POST",
		headers: getRequestHeaders(),
		body: JSON.stringify(body),
	})
		.then((response) => response.json())
		.then((response) => {
      if (response.status >= 400) {
				callback(false, null);
				createLog("Error", `Set Billing Address Status: ${response.status}`);
				return;
			}
			callback(true, response);
		})
		.catch((error) => {
			callback(false, null);
			createLog("Error", `Set Billing Address : ${error}`);
		});
}

export const getBillingHistoryRequest = (callback) => {
  fetch(`${AppConfig.SERVICE_BASE_URL}/api/v1/payment/billinghistory`, {
		method: "GET",
		headers: getRequestHeaders(),
	})
		.then((response) => response.json())
		.then((response) => {
			if (response.status >= 400) {
				callback(false, null);
				createLog("Error", `Get Billing History Status: ${response.status}`);
				return;
			}
			callback(true, response);
		})
		.catch((error) => {
			callback(false, null);
			createLog("Error", `Get Billing History: ${error}`);
		});
};

export const initiatePaymentSubscriptionRequest = (type, callback) => {
  fetch(`${AppConfig.SERVICE_BASE_URL}/api/v1/payment/subscription/premium/${type}`, {
    method: "POST",
    headers: getRequestHeaders(),
  })
    .then((response) => response.json())
    .then((response) => {
      callback(true, response);
    })
    .catch((error) => {
      callback(false, null);
      createLog("Error", `Initiate Subscription Payment: ${error}`);
    });
};

export const switchPaymentSubscriptionRequest = (type, callback) => {
  fetch(`${AppConfig.SERVICE_BASE_URL}/api/v1/payment/subscription/premium/${type}`, {
    method: "PATCH",
    headers: getRequestHeaders(),
  })
    .then((response) => {
      callback(true, response);
    })
    .catch((error) => {
      callback(false, null);
      createLog("Error", `Switch Subscription Payment: ${error}`);
    });
};

export const unsubscribePaymentRequest = (callback) => {
  fetch(`${AppConfig.SERVICE_BASE_URL}/api/v1/payment/subscription/premium`, {
    method: "DELETE",
    headers: getRequestHeaders(),
  }).then(() => {
    callback(true, null);
  })
    .catch((error) => {
      callback(false, null);
      createLog("Error", `Unsubscribe Payment: ${error}`);
    });
};

export const getSubscriptionTypesRequest = (callback) => {
  fetch(`${AppConfig.SERVICE_BASE_URL}/api/v1/payment/subscription/premium`, {
    method: "GET",
    headers: getRequestHeaders(),
  })
    .then((response) => response.json())
    .then((response) => {
      callback(true, response);
    })
    .catch((error) => {
      callback(false, null);
      createLog("Error", `Get Subscription Types : ${error}`);
    });
};

export const onSuccessPaymentRequest = (id, body, callback) => {
  fetch(`${AppConfig.SERVICE_BASE_URL}/api/v1/payment/${id}/success`, {
    method: "POST",
    headers: getRequestHeaders(),
    body: body,
  })
    .then((response) => response.json())
    .then((response) => {
      callback(true, response);
    })
    .catch((error) => {
      callback(false, null);
      createLog("Error", `On Success Payment : ${error}`);
    });
};

export const onCancelPaymentRequest = (id, body, callback) => {
  fetch(`${AppConfig.SERVICE_BASE_URL}/api/v1/payment/${id}/cancel`, {
    method: "POST",
    headers: getRequestHeaders(),
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((response) => {
      callback(true, response);
    })
    .catch((error) => {
      callback(false, null);
      createLog("Error", `On Cancel Payment : ${error}`);
    });
};
