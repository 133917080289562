import CheckBox from "__utilities/components/CheckBox";
import CustomButton from "__utilities/components/CustomButton";
import InputField from "__utilities/components/InputField";
import useFormValidationBilling from "_data/hooks/payment/useFormValidationBilling";
import { AppConfig } from "AppConfig";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";

export default function BillingForm({
  subscribe = () => {},
  getInitialBillingAddress,
  sendBillingAddress,
  initialEmail,
  showAcceptingTerms,
}) {
  const [companyName, setCompanyName] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [postnumber, setPostnumber] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [email, setEmail] = useState(initialEmail);
  const [cvr, setCvr] = useState("");
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);

  const {
    errorCompanyName,
    errorCompanyAddress,
    errorPostnumber,
    errorCity,
    errorCountry,
    errorEmail,
    errorCvr,
    errorIsTermsAccepted,
    validateForm,
  } = useFormValidationBilling(
    companyName,
    companyAddress,
    postnumber,
    city,
    country,
    email,
    cvr,
    isTermsAccepted
  );

  const setInitialData = (data) => {
    setCompanyName(data.companyName);
    setCompanyAddress(data.address);
    setPostnumber(data.postalCode);
    setCity(data.city);
    setCountry(data.country);
    setEmail(data.email);
    setCvr(data.vatNumber);
  };

  const openTerms = () => {
    window.open(
      '/terms.html',
      "_blank",
      "noopener,noreferrer"
    );
  };

  useEffect(() => {
    getInitialBillingAddress((success, response) => {
      if (success) {
        if (response.email !== null) {
          setInitialData(response);
        }
      }
    });
  }, []);

  const sendBillingDataAndSubscribe = () => {
    if (!validateForm(showAcceptingTerms)) {
      return;
    }

    let billingDataRequest = {
      companyName: companyName,
      address: companyAddress,
      postalCode: postnumber,
      city: city,
      country: country,
      email: email,
      vatNumber: cvr,
    };

    sendBillingAddress(billingDataRequest, (success) => {
      if (success) {
        toast.success("Faktureringsoplysninger gemt.");
        subscribe();
      }
    });
  };

  return (
    <div className="flex flex-col w-full gap-y-2 ">
      <InputField
        name={"Firmanavn:"}
        placeholder="Indast venglist"
        value={companyName}
        onChange={setCompanyName}
        error={errorCompanyName}
      />
      <InputField
        name={"Adresse:"}
        placeholder="Indast venglist"
        value={companyAddress}
        onChange={setCompanyAddress}
        error={errorCompanyAddress}
      />
      <InputField
        name={"Postnr.:"}
        placeholder="Indast venglist"
        value={postnumber}
        onChange={setPostnumber}
        error={errorPostnumber}
      />
      <InputField
        name={"By:"}
        placeholder="Indast venglist"
        value={city}
        onChange={setCity}
        error={errorCity}
      />
      <InputField
        name={"Land:"}
        placeholder="Indast venglist"
        value={country}
        onChange={setCountry}
        error={errorCountry}
      />
      <InputField
        name={"E-mail:"}
        placeholder="Indast venglist"
        value={email}
        onChange={setEmail}
        error={errorEmail}
      />
      <InputField
        name={"CVR.nr:"}
        placeholder="Indast venglist"
        value={cvr}
        onChange={setCvr}
        error={errorCvr}
      />
      {showAcceptingTerms && (
        <CheckBox
          isChecked={isTermsAccepted}
          text={
            <p>
              Jeg har læst og accepterer{" "}
              <span
                className="underline text-blue-500 cursor-pointer"
                onClick={() => openTerms()}
              >
                betingelserne for databehandling og anvendelsen af NemEkstrakt. 
              </span>
            </p>
          }
          onChange={() => setIsTermsAccepted(!isTermsAccepted)}
          error={errorIsTermsAccepted}
        />
      )}
      <CustomButton
        text={showAcceptingTerms ? "Gå til betaling" : "Gem data"}
        styles={"mt-2"}
        onClick={() => sendBillingDataAndSubscribe()}
      />
    </div>
  );
}
