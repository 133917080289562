import CustomButton from "./CustomButton";
import Popup from "./Popup";

export default function ConfirmationPopup({
  text,
  cancelPopup,
  confirmContinue,
}) {
  return (
    <Popup styles={"w-[50%]"}>
      <div className="flex flex-col w-full h-full justify-center items-center gap-y-4 p-5">
        <p className="text-primaryBlue font-latoBold text-[22px]">
        {text}
        </p>
        <div className="flex flex-row w-full justify-center gap-x-5">
          <CustomButton
            onClick={cancelPopup}
            text="Annuller"
            styles={
              "w-1/2 border border-primaryBlue bg-[#FFFFFF] text-[#003057] font-latoBold text-standardSmall"
            }
          />
          <CustomButton
            onClick={() => confirmContinue()}
            text="Fortsæt"
            styles={"w-1/2 text-standardSmall font-latoBold"}
          />
        </div>
      </div>
    </Popup>
  );
}
