import CustomButton from "__utilities/components/CustomButton";
import usePayment from "_data/hooks/payment/usePayment";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function OnPaymentCancelView() {
  const { onPaymentCancel } = usePayment();
  const navigate = useNavigate();

  useEffect(() => {
    onPaymentCancel();
  }, []);

  const onGoToHome = () => {
    navigate("/");
  };

  return (
    <div className="flex flex-col w-full h-full justify-center items-center">
      <div className="flex flex-col w-1/2 h-1/2 p-3 justify-around items-center border border-lightGrey rounded-xl">
        <p className="text-[34px] font-latoBold">Betaling annulleret</p>
        <div className="flex flex-col w-full justify-center items-center">
          <p className="text-[24px] font-latoBold">
            Din betaling er blevet annulleret!
          </p>
          <p className="text-standard font-latoBold">
            Hvis dette var en fejl, bedes du prøve igen eller kontakte vores
            supportteam for assistance.
          </p>
        </div>
        <CustomButton
          text={"Gå til startside"}
          onClick={onGoToHome}
          styles={"px-3 rounded-xl"}
        />
      </div>
    </div>
  );
}
