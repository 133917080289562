import { useEffect, useRef } from "react"
 
const useDidMountEffect = (func, cleanUp, deps) => {
  const didMount = useRef(false);
 
  useEffect(() => {
    if (didMount.current) func();
    else didMount.current = true;

    return cleanUp

  }, deps);
};
 
export default useDidMountEffect;