import React from "react";
import TermsSection from "./PageComponents/TermsSection";
import CustomButton from "__utilities/components/CustomButton";
import { useNavigate } from "react-router-dom";

export default function TermsView() {
  const navigate = useNavigate()

  return (
    <div className="w-full h-full px-[20%] my-[10px]">
      <div className="items-center justify-center text-title font-latoBold text-primaryBlue flex mb-[20px]">
        Ansvarsfraskrivelse og brugerbetingelser for brug af NemEkstrakt.dk
      </div>

      <TermsSection
        title={"1. Generelt"}
        text={
          'Dette værktøj ("NemEkstrakt.dk") ejes og drives af ditAdvokathus ApS, registreret i Danmark med cvr.nr. 4021 5301. Værktøjet giver brugeren mulighed for at uploade og arbejde med personoplysninger samt personfølsomme oplysninger for at generere ekstrakter til en retssag.'
        }
      />
      <TermsSection
        title={"2. Accept af ansvarsfraskrivelse"}
        text={
          "Ved at bruge NemEkstrakt.dk accepterer du denne ansvarsfraskrivelse og vores brugerbetingelser. Hvis du ikke accepterer disse vilkår, må du ikke bruge værktøjet."
        }
      />
      <TermsSection
        title={"3. Teknisk"}
        text={
          "Front-end delen af hjemmesiden er en skræddersyet applikation udviklet i React, og indeholder ikke tredjeparts komponenter. Back-end delen, der håndterer PDF-transformationer, er en specialudviklet applikation i .NET og bruger iTextSharp-komponenten. Begge er hosted på Azure."
        }
      />
      <TermsSection
        title={"4. Databehandling og tredjepart"}
        text={
          "Data uploadet til NemEkstrakt.dk vil være placeret i skyen via Microsoft Azure i en begrænset periode på 15-20 minutter. Efter denne periode vil data kun blive gemt i den genererede PDF-fil, og ingen data vil være i cloud-løsningen."
        }
      />
      <TermsSection
        title={"5. Ingen adgang til data"}
        text={
          "DitAdvokathus ApS eller andre involverede parter har på intet tidspunkt adgang til at læse eller analysere de data, der uploades til NemEkstrakt.dk. Alle data er krypteret og intet gemmes efter generering af PDF-filen."
        }
      />
      <TermsSection
        title={"6. Sikkerhed og tekniske foranstaltninger"}
        text={
          "Brugeren anerkender og accepterer, at manipulationen af PDF-filer primært foregår i hukommelsen. Midlertidige filer bruges sjældent og slettes straks efter brug, idet de også er krypteret med en stærk midlertidig nøgle. Der foretages ingen specielle kontroller af uploadede PDF-filer. Fra applikationens synspunkt er en PDF fil gyldig, hvis den kan åbnes og indholdet læses."
        }
      />
      <TermsSection
        title={"7. Ansvarsbegrænsning"}
        text={
          "DitAdvokathus ApS og NemEkstrakt.dk fraskriver sig ethvert ansvar for tab, skade eller uautoriseret adgang til dine data. Brugeren er selv ansvarlig for eventuelle krænkelser af privatlivets fred eller overtrædelser af databeskyttelseslovgivningen."
        }
      />
      <TermsSection
        title={"8. Jurisdiktion"}
        text={
          "Denne ansvarsfraskrivelse skal fortolkes i overensstemmelse med dansk ret, og eventuelle tvister skal afgøres af de danske domstole."
        }
      />
      <div className="text-textGray text-standardLarge pb-[20px]">
        <span className="font-latoBold text-primaryBlue">Vigtigt:</span> Ved at
        bruge NemEkstrakt.dk bekræfter du at have læst, forstået og accepteret
        denne ansvarsfraskrivelse.{" "}
        <CustomButton
          styles={"h-[20px] mt-[20px]"}
          text="Tilbage"
          onClick={() => navigate("/")}
        />
      </div>
    </div>
  );
}
