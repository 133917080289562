import React from "react";

const InputField = React.forwardRef(
  (
    {
      name,
      placeholder,
      error,
      isEnabled = true,
      value,
      onChange,
      tooltipContent,
      tooltipId,
      floatingContent,
      clearContent,
      multiline = false,
      styles,
      onClick,
      selectedCourt = "",
    },
    ref
  ) => (
    <div
      onClick={onClick}
      className={`w-full h-[48px] flex bg-white border-[1px]  ${
        error ? "border-red-500" : "border-textLightGray"
      } ${styles}`}
      data-tooltip-content={tooltipContent}
      data-tooltip-id={tooltipId}
      data-tooltip-delay-show={800}
    >
      <div
        className={`${
          selectedCourt === "Supreme" ? "w-[55%]" : "w-[37%]"
        } flex justify-start items-center text-standard ${
          isEnabled ? "text-primaryBlue" : "text-textLightGray"
        } pl-[15px] border-r-[1px] mr-[5px] my-[5px]`}
      >
        {name}
      </div>
      <div
        className={`relative w-full h-full ${
          isEnabled ? "text-primaryBlue" : "text-textXLightGray"
        } `}
      >
        {multiline ? (
          <textarea
            type="text"
            className="w-full h-full absolute resize-none outline-none text-standard"
            ref={ref}
            placeholder={placeholder}
            defaultValue={value}
            onInput={(e) => {
              onChange(isEnabled && e.target.value);
            }}
          />
        ) : (
          <input
            type="text"
            className="w-full h-full absolute outline-none text-standard"
            ref={ref}
            placeholder={placeholder}
            defaultValue={value}
            onInput={(e) => {
              onChange(isEnabled && e.target.value);
            }}
          />
        )}
        {floatingContent && (
          <div
            className={`w-full h-full absolute ${
              isEnabled ? "text-primaryBlue" : "text-textLightGray"
            } ${styles}`}
          >
            <div className="h-full flex items-center">{floatingContent}</div>
          </div>
        )}
        {clearContent && (
          <div
            className={`w-full h-full absolute z-100 ${
              isEnabled ? "text-primaryBlue" : "text-textLightGray"
            }`}
          >
            <div className="h-full flex items-center justify-end px-3 cursor-pointer">
              {clearContent}
            </div>
          </div>
        )}
      </div>
    </div>
  )
);

export default InputField;
