import { LogLevel } from '@azure/msal-browser';
import { AppConfig } from "AppConfig";

const msalConfig = {
	auth: {
		clientId: AppConfig.AUTH_CLIENT_ID,
		authority: `https://${AppConfig.AUTH_DOMAIN_NAME}.b2clogin.com/${AppConfig.AUTH_DOMAIN_NAME}.onmicrosoft.com/${AppConfig.AUTH_POLICY_SIGN_UP_SIGN_IN}`,
		knownAuthorities: [`${AppConfig.AUTH_DOMAIN_NAME}.b2clogin.com`],
		redirectUri: AppConfig.AUTH_REDIRECT_URL,
		postLogoutRedirectUri: '/',
		navigateToLoginRequestUrl: false,
	},
	cache: {
		cacheLocation: "localStorage",
		storeAuthStateInCookie: AppConfig.AUTH_STORE_IN_COOKIE,
		claimsBasedCachingEnabled: true,
	},
	system: {
		loggerOptions: {
			loggerCallback: (level, message, containsPii) => {
				if (containsPii) {
					return;
				}
				switch (level) {
					case LogLevel.Error:
						console.error('MSAL', message);
						return;
					case LogLevel.Info:
						console.info('MSAL', message);
						return;
					case LogLevel.Verbose:
						console.debug('MSAL', message);
						return;
					case LogLevel.Warning:
						console.warn('MSAL', message);
						return;
					default:
						return;
				}
			},
		},
	},
};

export const loginRequest = {
	scopes: AppConfig.AUTH_API_SCOPE
		? [`${AppConfig.AUTH_API_SCOPE}`, 'offline_access']
		: ['offline_access'],
};

export default msalConfig;
