import React from "react";
import ReactDOM from "react-dom/client";
import "index.css";
import { OverlayProvider } from "_data/context/overlay";

import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import msalConfig from "./msal/authConfig";
import Routing from "app/Routing";

const msalInstance = new PublicClientApplication(msalConfig);
await msalInstance.initialize();
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<OverlayProvider>
		<MsalProvider instance={msalInstance}>
			<Routing />
		</MsalProvider>
	</OverlayProvider>
);
