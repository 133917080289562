import CustomButton from "__utilities/components/CustomButton";
import usePayment from "_data/hooks/payment/usePayment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function OnPaymentSuccessView() {
  const { onPaymentSuccess } = usePayment();

  const navigate = useNavigate();

  const [paymentData, setPaymentData] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const onGoToHome = () => {
		navigate("/");
		window.location.reload();
  };

  useEffect(() => {
    onPaymentSuccess(setIsLoading, setPaymentData);
  }, []);

  return (
    <div className="flex flex-col w-full h-full justify-center items-center">
      {isLoading && <div>Loading...</div>}
      <div className="flex flex-col w-1/2 h-1/2 p-3 justify-around items-center border border-lightGrey rounded-xl">
        <p className="text-[34px] font-latoBold">Betaling gennemført</p>
        <div className="flex flex-col w-full justify-center items-center">
          <p className="text-[24px] font-latoBold">Tak!</p>
          <p className="text-standard font-latoBold">
          Vi har modtaget din betaling
          </p>
        </div>

        <CustomButton
          text={"Gå til startside"}
          onClick={onGoToHome}
          styles={"px-3 rounded-xl"}
        />
      </div>
    </div>
  );
}
