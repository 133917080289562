import { getUserInfoRequest } from "_data/services/auth/service";
import {
  getBillingAddressRequest,
  setBillingAddressRequest,
  unsubscribePaymentRequest,
  initiatePaymentSubscriptionRequest,
  onCancelPaymentRequest,
  onSuccessPaymentRequest,
  switchPaymentSubscriptionRequest,
  getBillingHistoryRequest,
  getSubscriptionTypesRequest,
} from "_data/services/payment/service";
import { useParams, useNavigate } from "react-router-dom";

export default function usePayment() {
  const params = useParams();
  const navigate = useNavigate();

  const getUserInfoData = (callback = () => {}) => {
    getUserInfoRequest((success, response) => {
      callback(success, response);
    });
  };

  const getInitialBillingAddress = (callback = () => {}) => {
    getBillingAddressRequest((success, response) => {
      callback(success, response);
    });
  };

  const sendBillingAddress = (body, callback) => {
    setBillingAddressRequest(body, (success, response) => {
      callback(success, response);
    });
  };

  const getBillingHistory = (callback = () => {}) => {
    getBillingHistoryRequest((success, response) => {
      callback(success, response);
    });
  };

  const getSubscriptionTypes = (callback = () => {}) => {
    getSubscriptionTypesRequest((success, response) => {
      callback(success, response);
    });
  };

  const initiatePayment = (type, callback) => {
    initiatePaymentSubscriptionRequest(type, (success, response) => {
      if (success) {
        if (response) {
          if (response.paymentNeeded) {
            if (response.paymentUrl) {
              window.location.href = response.paymentUrl;
            }
          } else {
            callback(true);
          }
        }
      }
    });
  };

  const switchPayment = (type, callback) => {
    switchPaymentSubscriptionRequest(type, (success, response) => {
      if (success) {
        callback();
      }
    });
  };

  const unsubscribePayment = (callback = () => {}) => {
    unsubscribePaymentRequest((success, response) => {
      callback(success, response);
    });
  };

  
  const onPaymentSuccess = (
    setIsLoading = () => {},
    setPaymentData = () => {}
  ) => {
    const { id } = params;

    let queryParams = window.location.search;

    queryParams = `"${queryParams.replace("?", "")}"`;

    setIsLoading(true);
    onSuccessPaymentRequest(id, queryParams, (success, data) => {
      setIsLoading(false);
      if (success) {
        setPaymentData(data);
      }
    });
  };

  const onPaymentCancel = (setIsLoading = () => {}) => {
    const { id } = params;

    let queryParams = window.location.search;

    queryParams = `"${queryParams.replace("?", "")}"`;

    setIsLoading(true);
    onCancelPaymentRequest(id, queryParams, (success, data) => {
      setIsLoading(false);
      if (success) {
      }
    });
  };

  return {
    getUserInfoData,
    getInitialBillingAddress,
    sendBillingAddress,
    getBillingHistory,
    getSubscriptionTypes,
    initiatePayment,
    switchPayment,
    unsubscribePayment,
    onPaymentSuccess,
    onPaymentCancel,
  };
}
