import CustomButton from "__utilities/components/CustomButton";
import Section from "__utilities/components/Section";
import { addToSession } from "__utilities/functions/sessionHandler";
import useDidMountEffect from "__utilities/functions/useDidMount";
import { helperContext } from "_data/context/helper";
import AdvancedForm from "_features/AdvancedForm";
import CaseInfoForm from "_features/CaseInfoForm";
import DroppableItem from "_features/DroppableItem";
import DroppableList from "_features/DroppableList";
import React, { useContext, useRef } from "react";

export default function ExtractContent({
  headers,
  showCaseInfoForm,
  setShowCaseInfoForm,
  showAdvancedForm,
  setShowAdvancedForm,
  onSortClick,
  onItemDelete,
  onItemEdit,
  editExtractedFile,
  margeDocuments,
  mergeUploadKey,
  editMode,
  userInfo,
  showFileErrors,
  setShowFileErrors,
  validateDates,
  validateRequiredItems,
  validateMandatoryHeaders,
  validateForm,
}) {
  const { logoEnabled, letterheadEnabled, timelineEnabled } =
    useContext(helperContext);
  const caseInfoFormRef = useRef(null);
  const showMandatoryFields = () => {
    setShowCaseInfoForm(true);
    caseInfoFormRef.current.scrollIntoView({ behavior: "smooth" });
  };

  useDidMountEffect(
    () => {
      sessionStorage.removeItem("headers_data");
      addToSession("headers_data", headers);
    },
    () => {},
    [headers]
  );

  return (
    <div ref={caseInfoFormRef} className="px-[40px] py-[30px]">
      <Section
        title="Sagsoplysninger:"
        isOpen={showCaseInfoForm ? showCaseInfoForm : false}
        setIsOpen={setShowCaseInfoForm}
        styles="mb-[30px]"
      >
        <CaseInfoForm />
      </Section>
      <Section
        title="Layout:"
        isOpen={showAdvancedForm ? showAdvancedForm : false}
        setIsOpen={setShowAdvancedForm}
        styles="mb-[30px]"
      >
        <AdvancedForm
          isEnabledLogo={logoEnabled}
          isEnabledLetterhead={letterheadEnabled}
          isEnabledTimeline={timelineEnabled}
        />
      </Section>
      <div className="mb-[16px] text-primaryBlue text-standardXLarge font-latoBold">
        Dokumentation:
      </div>

      <div className="mb-[30px]">
        {headers?.map((header) => {
          if (!header.isMultiple) {
            return (
              <DroppableItem
                key={header.id}
                droppableId={header.id}
                item={{
                  id: header.usedFiles?.[0]?.id,
                  name: header.id,
                  index: 0,
                  fileName: header.name,
                }}
                hasPlaceholder={true}
                userInfo={userInfo}
                isSelected={
                  header.usedFiles?.[0]?.content !== null &&
                  header.usedFiles?.[0]?.content !== undefined
                    ? true
                    : false
                }
                onItemDelete={onItemDelete}
                error={
                  (header.usedFiles?.[0]?.content === null ||
                    header.usedFiles?.[0]?.content === undefined) &&
                  showFileErrors
                    ? true
                    : false
                }
              />
            );
          } else {
            return (
              <DroppableList
                key={header.id}
                header={header.id}
                isSortable={header.isSortable}
                onSortClick={onSortClick}
                droppableId={header.id}
                fileList={header.usedFiles}
                styles={`mb-[12px]`}
                hasPlaceholder={true}
                userInfo={userInfo}
                onItemDelete={onItemDelete}
                onItemEdit={onItemEdit}
                elementsHasError={true}
                listHasError={
                  !header.required &&
                  header.isMandatory &&
                  ((header?.usedFiles?.length ?? 0) === 0 ||
                    (header?.usedFiles?.length > 0 &&
                      header?.usedFiles?.find(
                        (f) => f.content === null || f.content === undefined
                      ))) &&
                  showFileErrors
                    ? true
                    : false
                }
                listHasDate={true}
              />
            );
          }
        })}
      </div>
      <CustomButton
        text="OPRET EKSTRAKT"
        styles="text-standardSmall"
        onClick={() => {
          setShowFileErrors(true);
          let isValid = validateForm();
          isValid &= validateDates();
          isValid &= validateRequiredItems();
          isValid &= validateMandatoryHeaders();
          !isValid && showMandatoryFields();
          if (!isValid) return;

          if (editMode) {
            editExtractedFile(headers);
          } else {
            margeDocuments(mergeUploadKey, headers);
          }
        }}
      />
    </div>
  );
}
