import React from "react";
import { Tooltip } from "react-tooltip";

const AppTooltip = ({ componentIds,extended = false } ) => {
  return componentIds.map((element) => (
    <Tooltip
      key={element}
      id={element}
      place="bottom"
      border="1px solid #E6EAEE"
      style={{ width: extended ? "320px" : "160px", textAlign:"center", borderRadius:"0px", background:"#FFFFFF", color:"#003057", fontSize: '12px', zIndex: 50 }}
    />
  ));
};

export default AppTooltip;