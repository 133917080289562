import usePayment from "_data/hooks/payment/usePayment";
import BillingForm from "_features/BillingForm";
import { useLocation } from "react-router-dom";

export function BillingView() {
  const { initiatePayment, getInitialBillingAddress, sendBillingAddress } =
    usePayment();

  const location = useLocation();
  let subscriptionType = location.state?.subscriptionType;
  const subscribe = () => {
    initiatePayment(subscriptionType, () => {
      //   refreshUser()
    });
  };

  return (
    <div className="flex flex-col w-full h-full justify-center items-center my-[20px]">
      <div className="flex flex-col w-2/5 h-fit items-center border-[1px] border-primaryBlue p-5">
        <BillingForm
          subscribe={subscribe}
          getInitialBillingAddress={getInitialBillingAddress}
          sendBillingAddress={sendBillingAddress}
          showAcceptingTerms={location.pathname.includes("billing")}
        />
      </div>
    </div>
  );
}
