import CustomButton from "./CustomButton";
import Popup from "./Popup";

export default function SubscriptionCancelPopup({keepSubscription, confirmCancellation}) {
  return (
    <Popup styles={"w-[50%]"}>
      <div className="flex flex-col w-full h-full justify-center items-center gap-y-4 p-5">
        <p className="text-center text-primaryBlue font-latoBold text-[22px]">
        Er der sikker på, du ønsker at opsige dit abonnement?
        </p>
        <p className="text-center font-normal text-textXLightGray">
        Hvis du opsiger dit abonnement, vil du miste dine premium features ved abonnementets udløb.
          <br />
          Du kan gentegne dit abonnement på et hvert tidspunkt under dine profilindstillinger.
        </p>
        <div className="flex flex-row w-full justify-center gap-x-5">
          <CustomButton onClick={keepSubscription} text="BEHHOLD ABONEMENT" styles={"w-1/2 border border-primaryBlue bg-[#FFFFFF] text-[#003057] font-latoBold text-standardXSmall"} />
          <CustomButton onClick={confirmCancellation} text="BEKRÆFT OPSIGELSE"  styles={"w-1/2 text-standardXSmall font-latoBold"}/>
        </div>
      </div>
    </Popup>
  );
}
