import usePayment from "_data/hooks/payment/usePayment";
import React, { useEffect, useState } from "react";

export const helperContext = React.createContext({});

export default function HelperProvider({ children }) {
  const [userInfo, setUserInfo] = useState();

  const { getUserInfoData } = usePayment();

  const refreshUserInfo = async () => {
    await getUserInfoData((success, userInfoResponse) => {
      if (success) {
        setUserInfo(userInfoResponse);
      }
    });
  };

  useEffect(() => {
    refreshUserInfo();
  }, []);

  const nationalCourtEnabled = () => {
    switch (userInfo?.executionLicense) {
      case "Freemium":
        return false;
      case "Premium":
        return true;
      case "Enterprise":
        return true;
      default:
        return false;
    }
  };

  const supremeCourtEnabled = () => {
    switch (userInfo?.executionLicense) {
      case "Freemium":
        return false;
      case "Premium":
        return true;
      case "Enterprise":
        return true;
      default:
        return false;
    }
  };

  const logoEnabled = () => {
    switch (userInfo?.executionLicense) {
      case "Freemium":
        return false;
      case "Premium":
        return true;
      case "Enterprise":
        return true;
      default:
        return false;
    }
  };

  const letterheadEnabled = () => {
    switch (userInfo?.executionLicense) {
      case "Freemium":
        return false;
      case "Premium":
        return true;
      case "Enterprise":
        return true;
      default:
        return false;
    }
  };

  const timelineEnabled = () => {
    switch (userInfo?.executionLicense) {
      case "Freemium":
        return false;
      case "Premium":
        return true;
      case "Enterprise":
        return true;
      default:
        return false;
    }
  };

  return (
    <helperContext.Provider
      value={{
        nationalCourtEnabled: nationalCourtEnabled(),
        supremeCourtEnabled: supremeCourtEnabled(),
        logoEnabled: logoEnabled(),
        letterheadEnabled: letterheadEnabled(),
        timelineEnabled: timelineEnabled(),
      }}
    >
      {children}
    </helperContext.Provider>
  );
}
