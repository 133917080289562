import React, { useState } from "react";

export const overlayContext = React.createContext({});

export const OverlayProvider = ({ children }) => {
  const [overlay, setOverlay] = useState({ show: false, content: <></> });
  const [overlayLock, setOverlayLock] = useState(false);

  return (
    <overlayContext.Provider
      value={{
        overlay,
        setOverlay,
        overlayLock,
        setOverlayLock,
      }}
    >
      {children}
    </overlayContext.Provider>
  );
};
