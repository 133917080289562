import ConfirmationPopup from "__utilities/components/ConfirmationPopup";
import { clearExtractSession } from "__utilities/functions/sessionHandler";

export const navigateTo = (
  setOverlay,
  isExtractActive,
  setIsExtractActive,
  onConfirm,
  onCancel = null
) => {
  if (isExtractActive) {
    setOverlay({
      show: true,
      content: (
        <ConfirmationPopup
          text={
            "Hvis du forlader siden, mister du alle ændringer. Er du sikker?"
          }
          cancelPopup={() => {
            setOverlay({ show: false, content: <></> });
            if (onCancel) { onCancel(); }
          }}
          confirmContinue={() => {
            clearExtractSession();
            setOverlay({ show: false, content: <></> });
            setIsExtractActive(false);
            if (onConfirm) { onConfirm(); }
          }}
        />
      ),
    });
  } else {
    if (onConfirm) { onConfirm(); }
  }
};

export const navigateBack = (
      setOverlay,
      onConfirm,
      onCancel = null
  ) => {
  setOverlay({
    show: true,
    content: (
      <ConfirmationPopup
        text={"Hvis du forlader siden, mister du alle ændringer. Er du sikker?"}
        cancelPopup={() => {
          setOverlay({ show: false, content: <></> });
          if (onCancel) { onCancel(); }
        }}
        confirmContinue={() => {
          clearExtractSession();
          setOverlay({ show: false, content: <></> });
          if (onConfirm) { onConfirm(); }
        }}
      />
    ),
  });
};
