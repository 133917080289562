import SubscriptionCancelPopup from "__utilities/components/SubscriptionCancelPopup";
import SubscriptionSwitchPopup from "__utilities/components/SubscriptionSwitchPopup";
import SubscriptionUpgradePopup from "__utilities/components/SubscriptionUpgradePopup";

export default function AccountViewButtons({
  userInfo,
  refreshUserInfo,
  setOverlay,
  unsubscribePayment,
  switchSubscription,
}) {
  return (
    <>
      {!userInfo?.licenseHasExpired && userInfo?.subscriptionStatus === "Active" ? (
        <div className="flex flex-row w-full h-2/5 justify-around items-center border-t border-lightGrey gap-x-1 p-3">
          <div
            className={`flex flex-row ${
              userInfo?.subscriptionType ? "w-1/3" : "w-1/2"
            } tall:w-full justify-center items-center bg-white text-primaryBlue font-latoBold text-standardSmall p-5 border border-lightGrey cursor-pointer uppercase`}
            onClick={() => {
              setOverlay({
                show: true,
                content: (
                  <SubscriptionCancelPopup
                    keepSubscription={() =>
                      setOverlay({ show: false, content: <></> })
                    }
                    confirmCancellation={() => {
                      unsubscribePayment(() => {
                        refreshUserInfo();
                      });
                      setOverlay({ show: false, content: <></> });
                    }}
                  />
                ),
              });
            }}
          >
            Opsig abonnement
          </div>
          {userInfo?.subscriptionType && (
            <div
              className="flex flex-row w-1/3 tall:w-full justify-center items-center bg-primaryBlue text-white font-latoBold text-standardSmall p-5 cursor-pointer uppercase"
              onClick={() =>
                setOverlay({
                  show: true,
                  content: (
                    <SubscriptionSwitchPopup
                      hideOverlay={() =>
                        setOverlay({ show: false, content: <></> })
                      }
                      switchSubscription={() => {
                        switchSubscription();
                      }}
                      userInfo={userInfo}
                    />
                  ),
                })
              }
            >
              <p>
                Skift til{" "}
                {userInfo?.subscriptionType === "Year" ? "månedlig" : "årlig"}{" "}
                betaling
              </p>
            </div>
          )}
          <div
            className={`flex flex-row ${
              userInfo?.subscriptionType ? "w-1/3" : "w-1/2"
            } tall:w-full justify-center items-center bg-primaryBlue text-white font-latoBold text-standardSmall p-5 cursor-pointer uppercase`}
            onClick={() => {
              setOverlay({
                show: true,
                content: (
                  <SubscriptionUpgradePopup
                    hideOverlay={() =>
                      setOverlay({ show: false, content: <></> })
                    }
                    userInfo={userInfo}
                    refreshUser={() => refreshUserInfo()}
                  />
                ),
              });
            }}
          >
            <p>Skift abonnement</p>
          </div>
        </div>
      ) : (
        <div className="flex flex-row w-full h-2/5 justify-center items-center border-t border-lightGrey p-5 gap-x-5">
          <div
            className={`flex flex-row ${
              userInfo?.subscriptionStatus === "Cancelled" || userInfo?.subscriptionStatus === "Deleted" ? "w-1/2" : "w-1/3"
            } justify-center items-center bg-primaryBlue text-white font-latoBold text-standardLarge p-5 border border-lightGrey cursor-pointer uppercase`}
            onClick={() => {
              setOverlay({
                show: true,
                content: (
                  <SubscriptionUpgradePopup
                    hideOverlay={() =>
                      setOverlay({ show: false, content: <></> })
                    }
                    userInfo={userInfo}
                    refreshUser={() => refreshUserInfo()}
                  />
                ),
              });
            }}
          >
            {userInfo?.subscriptionStatus === "Cancelled" || userInfo?.subscriptionStatus === "Deleted"
              ? "Genaktiver abonnement"
              : "Forny abonnement"}
          </div>
        </div>
      )}
    </>
  );
}
