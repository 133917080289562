export default function CustomButton({
  text,
  onClick,
  tooltipText,
  tooltipId,
  styles,
  children,
  onMouseEnter,
  onMouseLeave,
  enabled = true
}) {
  return (
    <div
      data-tooltip-id={tooltipId}
      data-tooltip-content={tooltipText}
      data-tooltip-delay-show={800}
      className={`w-full flex justify-center items-center ${enabled ? 'text-white' : 'text-gray-500'} bg-primaryBlue py-[17px] text-standardXLarge ${enabled && "cursor-pointer"} select-none ${styles}`}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {text}
      {children}
    </div>
  );
}
