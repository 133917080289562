import { useState } from "react";

export default function useFormValidation(
	selectedCourt,
	courtNumber,
	nameOfPlaintiff,
	addressOfPlaintiff,
	lawyerOfPlaintiff,
	nameOfDefendant,
	addressOfDefendant,
	lawyerOfDefendant,
	selectedStartDate
) {
	const [errorCourtNumber, setErrorCourtNumber] = useState("");
	const [errorNameOfPlaintiff, setErrorNameOfPlaintiff] = useState("");
	const [errorAddressOfPlaintiff, setErrorAddressOfPlaintiff] = useState("");
	const [errorLawyerOfPlaintiff, setErrorLawyerOfPlaintiff] = useState("");
	const [errorCvrOfPlaintiff, setErrorCvrOfPlaintiff] = useState("");
	const [errorNameOfDefendant, setErrorNameOfDefendant] = useState("");
	const [errorAddressOfDefendant, setErrorAddressOfDefendant] = useState("");
	const [errorLawyerOfDefendant, setErrorLawyerOfDefendant] = useState("");
	const [errorCvrOfDefendant, setErrorCvrOfDefendant] = useState("");
	const [errorHighCourtText, setErrorHighCourtText] = useState("");

	const [dataAndTimeError, setDataAndTimeError] = useState("");
	const [companyLogoError, setCompanyLogoError] = useState("");

	const clearErrorStates = () => {
		setErrorCourtNumber("");
		setErrorNameOfPlaintiff("");
		setErrorAddressOfPlaintiff("");
		setErrorLawyerOfPlaintiff("");
		setErrorNameOfDefendant("");
		setErrorAddressOfDefendant("");
		setErrorLawyerOfDefendant("");
		setErrorHighCourtText("");

		setDataAndTimeError("");
		setCompanyLogoError("");
	};

	const validateForm = () => {
		let isValid = true;

		clearErrorStates();

		if (selectedCourt === "") {
			isValid = false;
		}

		if (courtNumber === "") {
			isValid = false;
			setErrorCourtNumber("Required");
		}

		if (nameOfPlaintiff === "") {
			isValid = false;
			setErrorNameOfPlaintiff("Required");
		}

		if (addressOfPlaintiff === "") {
			isValid = false;
			setErrorAddressOfPlaintiff("Required");
		}

		if (lawyerOfPlaintiff === "") {
			isValid = false;
			setErrorLawyerOfPlaintiff("Required");
		}

		if (nameOfDefendant === "") {
			isValid = false;
			setErrorNameOfDefendant("Required");
		}

		if (addressOfDefendant === "") {
			isValid = false;
			setErrorAddressOfDefendant("Required");
		}

		if (lawyerOfDefendant === "") {
			isValid = false;
			setErrorLawyerOfDefendant("Required");
		}

		if (selectedCourt === "National" && !selectedStartDate) {
			isValid = false;
			setDataAndTimeError("Required");
		}

		return isValid;
	};

	return {
		errorCourtNumber,
		errorNameOfPlaintiff,
		errorAddressOfPlaintiff,
		errorLawyerOfPlaintiff,
		errorCvrOfPlaintiff,
		errorNameOfDefendant,
		errorAddressOfDefendant,
		errorLawyerOfDefendant,
		errorCvrOfDefendant,
		errorHighCourtText,
		dataAndTimeError,
		companyLogoError,
		clearErrorStates,
		validateForm,
	};
}
